// in src/Item.js
import { ChipField, useRecordContext } from "react-admin";

const CustomChipField = (props) => {
  const record = useRecordContext();
  if (record[props.source] != undefined) {
    return (
      <ChipField
        {...props}
        label={props.data?.[record[props.source]]?.label || undefined}
        //color={record[props.source] === "CANCELLED" ? "error" : "info"}
        color={props.data?.[record[props.source]]?.color || undefined}
      />
    );
  }
  return <div></div>;
};

export default CustomChipField;

import React from 'react';
import { Button, TextInput, useLogin, useNotify, Form, PasswordInput, Link, useRedirect } from 'react-admin';
import SendIcon from '@mui/icons-material/Send';

// in src/Item.js
import {
    Box,
  } from "@mui/material";
import authProvider from '../../api/authProvider';
import AuthLayout from './authLayout';

const RecoverPassword = () => {

    const redirect = useRedirect();
    const notify = useNotify();
    
    const submit = async (data) => {
        authProvider.recoverPassword(data).then(() => {
            notify("Password changed", { type: 'success' });
            redirect("/login");
        }).catch((e) => { 
            notify("Invalid email or password", { type: 'error' }); 
        });
    }    
        
    return (
        <AuthLayout title="Recover password">
            <Form onSubmit={submit}>
                <TextInput source="email" autoComplete="email" fullWidth />
                <PasswordInput source="new_password" autoComplete='off' fullWidth />
                <TextInput source="code" autoComplete="off" fullWidth />
                <Button type="submit" label="Change password"><SendIcon /></Button>
            </Form>
        </AuthLayout>
    );
};

export default RecoverPassword;
// in src/Item.js
import React, { useEffect, useState, useRef } from "react";
import { useRecordContext } from "react-admin";

import { Box } from "@mui/material";

import { Wrapper } from "@googlemaps/react-wrapper";
import config from "../../config/config";

const CustomMapField = (props) => {
  //   const {
  //     //    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
  //     //    error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
  //     //    isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
  //     //    isLoading, // boolean that is true until the record is available for the first time
  //     record, // record fetched via dataProvider.getOne() based on the id from the location
  //     //    refetch, // callback to refetch the record via dataProvider.getOne()
  //     //    resource, // the resource name, deduced from the location. e.g. 'posts'
  //   } = useRecordContext();
  const record = useRecordContext();

  return (
    <Box my={2} borderRadius={3} overflow="hidden">
      <Wrapper apiKey={config.apiPlaces}>
        <Map latitude={record.location[0]} longitude={record.location[1]}>
          <Marker
            position={{ lat: record.location[0], lng: record.location[1] }}
          />
        </Map>
      </Wrapper>
    </Box>
  );
};

const Map = ({ latitude, longitude, children }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          center: {
            lat: latitude ?? 0,
            lng: longitude ?? 0,
          },
          zoom: 16,
        })
      );
    }
  }, [ref, map, latitude, longitude]);

  return (
    <>
      <div ref={ref} style={{ height: 300, width: "100%" }} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default CustomMapField;

// in src/Item.js
import { useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import dataProvider from "../../api/dataProvider";
import { Box, Typography } from "@mui/material";

const CustomAnswersField = (props) => {
  const record = useRecordContext();
  const questionnaireId = record["questionnaire"];
  const answers = record["answers"];

  const [questionnaire, setQuestionnaire] = useState();
  const [answersObj, setAnswesrObj] = useState();

  useEffect(() => {
    let getQuestionnaire = async () => {
      let questionnaireReq = await dataProvider.getOne(
        "questionnaires",
        questionnaireId
      );
      //console.log(questionnaireReq.data);
      setQuestionnaire(questionnaireReq.data);
    };
    getQuestionnaire();
  }, [questionnaireId]);

  useEffect(() => {
    // console.log("=================");
    // let _textAnswers = [];
    let _answersObj = [{}];
    if (answers && questionnaire) {
      answers.map((_a, i) => {
        _a.code.reduce((_prev, _curr) => {
          // console.log(_prev);
          if (_prev.questions) {
            return _prev.questions[_curr];
          } else if (_prev.choices[_curr].child) {
            _answersObj.push({
              type: "q",
              text: _prev.text.es,
            });
            _answersObj.push({
              type: "a",
              text: _prev.choices[_curr].text.es,
            });
            return _prev.choices[_curr].child;
          } else {
            _answersObj.push({
              type: "q",
              text: _prev.text.es,
            });
            _answersObj.push({
              type: "a",
              text: _prev.choices[_curr].text.es,
            });
            return _prev.choices[_curr];
          }
          // }
        }, questionnaire);
        if (_a.value) {
          _answersObj.push({
            type: "a",
            text: _a.value,
          });
        }
        setAnswesrObj(_answersObj);
      });
    }
  }, [answers, questionnaire]);
  //   answers.map((_a, i) => {
  //     let question = questionnaireReq[i];
  //     // let _reportOption = _a.code.reduce((_prev, _curr) => {
  //     //   if (_prev.choices[_curr].alert) {
  //     //     return _prev.choices[_curr].alert;
  //     //   } else {
  //     //     return _prev.choices[_curr].child;
  //     //   }
  //     // }, _questionnaire.questions);

  //     let result = _a.code.reduce((_prev, curr) => {
  //       if (_prev.type === "textarea") {
  //         return _a.value;
  //       } else if (_prev.type === "choices") {
  //         return _prev.choi;
  //       }
  //     }, question);
  //   });

  //   const renderChild = (child, i, d) => {
  //     //console.log(d);
  //     return (
  //       <Box ml={d} key={`${i}{d}`}>
  //         <Typography fontWeight={"bold"}>{child.text.es}</Typography>
  //         {child.choices.map((_c, j) => {
  //           return (
  //             <Box key={`${i}${d}${j}`}>
  //               <Typography ml={d}>
  //                 · {_c.text.es} {i},{d},{j}
  //               </Typography>
  //               {_c.child ? renderChild(_c.child, i, d + 1) : null}
  //             </Box>
  //           );
  //           //   if (answers.find((_a) => _a.code === )) {

  //           //   } else {
  //           //     return <></>;
  //           //   }
  //         })}
  //       </Box>
  //     );
  //   };

  return (
    <Box>
      {/*questionnaire?.questions.map((_q, i) => {
        return renderChild(_q, i, 0);
      })*/}
      {answersObj?.map((_a, i) => {
        return (
          <Typography key={i} fontWeight={_a.type === "q" ? "bold" : null}>
            {_a.text}
          </Typography>
        );
      })}
    </Box>
  );
};

export default CustomAnswersField;

const customMenuItems = (resource, params) => {
    switch (resource) {
        case "deleted":
            params.filter = {...params.filter, status: "DELETED"}
            resource = "users";
            break;
        case "paused":
            params.filter = {...params.filter, status: "PAUSED"}
            resource = "users";
            break;
        case "coordinators":
            params.filter = {...params.filter, role: "coordinator"}
            resource = "users";
            break;
        default:
          break;
    }
    return { _resource: resource, _params: params };
}


const dataProviderExtended = (dataProvider) => ({
    ...dataProvider,
    getList: (resource, params) => {
        const { _resource, _params } = customMenuItems(resource, params);
        return dataProvider.getList(_resource, _params);
    },
    getOne: (resource, params) => {
        const { _resource, _params } = customMenuItems(resource, params);
        return dataProvider.getOne(_resource, _params);
    },
    getMany: (resource, params) => {
        const { _resource, _params } = customMenuItems(resource, params);
        return dataProvider.getMany(_resource, _params);
    },
    update: (resource, params) => {
        const { _resource, _params } = customMenuItems(resource, params);
        return dataProvider.update(_resource, _params);
    },
    create: (resource, params) => {
        const { _resource, _params } = customMenuItems(resource, params);
        return dataProvider.create(_resource, _params);
    },
    delete: (resource, params) => {
        const { _resource, _params } = customMenuItems(resource, params);
        return dataProvider.delete(_resource, _params);
    },
    getManyReference: (resource, params) => {
        const { _resource, _params } = customMenuItems(resource, params);
        return dataProvider.getManyReference(_resource, _params);
    },
    deleteMany: (resource, params) => {
        const { _resource, _params } = customMenuItems(resource, params);
        return dataProvider.deleteMany(_resource, _params);
    },
  })

  export default dataProviderExtended;
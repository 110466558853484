// in src/App.js
import * as React from "react";
import authProvider from "./api/authProvider";
import dataProvider from "./api/dataProvider";
import LoginPage from "./views/authentication/login";

import PostIcon from "@mui/icons-material/Book";
import UserIcon from "@mui/icons-material/Group";
import BusinessIcon from "@mui/icons-material/Business";
import QuizIcon from "@mui/icons-material/Quiz";
import ReportIcon from "@mui/icons-material/Report";
import InterestsIcon from "@mui/icons-material/Interests";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CollectionsIcon from "@mui/icons-material/Collections";
import PlaceIcon from "@mui/icons-material/Place";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import EmailIcon from "@mui/icons-material/Email";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BlockIcon from "@mui/icons-material/Block";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  defaultTheme,
  AppBar,
  ToggleThemeButton,
  Layout,
  ShowGuesser,
  CustomRoutes,
  Button,
  IconButtonWithTooltip,
  Link,
  useTheme,
} from "react-admin";
import Dashboard from "./Dashboard";

import { Route } from "react-router-dom";

import { UserList, UserEdit, UserCreate, UserShow } from "./views/users/users";
import {
  InstitutionList,
  InstitutionEdit,
  InstitutionCreate,
  InstitutionShow,
} from "./views/institutions/institutions";
import { PlanList, PlanEdit, PlanCreate, PlanShow } from "./views/plans/plans";
import { PostList, PostEdit, PostCreate } from "./views/posts/posts";
import {
  MeetingCreate,
  MeetingEdit,
  MeetingList,
  MeetingShow,
} from "./views/meetings/meetings";
import {
  QuestionCreate,
  QuestionEdit,
  QuestionList,
  QuestionShow,
} from "./views/questions/questions";
import {
  ReportCreate,
  ReportEdit,
  ReportList,
  ReportShow,
} from "./views/reports/reports";
import {
  InterestCreate,
  InterestEdit,
  InterestList,
  InterestShow,
} from "./views/interests/interests";

import { createTheme, Box, Typography } from "@mui/material";

import {
  MAIN_COLOR,
  SECONDARY_COLOR,
  GREY_20_COLOR,
  NEUTRAL_DARK,
} from "./utils/colors";
import indigo from "@mui/material/colors/indigo";
import pink from "@mui/material/colors/pink";
import red from "@mui/material/colors/red";
import {
  UserPlanCreate,
  UserPlanEdit,
  UserPlanList,
  UserPlanShow,
} from "./views/userPlans/userPlans";
import {
  MeetingReviewCreate,
  MeetingReviewEdit,
  MeetingReviewList,
  MeetingReviewShow,
} from "./views/meetingReviews/meetingReviews";
import {
  NotificationCreate,
  NotificationEdit,
  NotificationList,
  NotificationShow,
} from "./views/notifications/notifications";
import {
  MeetingPlaceCreate,
  MeetingPlaceEdit,
  MeetingPlaceList,
  MeetingPlaceShow,
} from "./views/meetingPlaces/meetingPlaces";
import {
  ResourceCreate,
  ResourceEdit,
  ResourceList,
  ResourceShow,
} from "./views/resources/resources";
import { CoordinatorList } from "./views/coordinators/coordinators";
import {
  PlanReviewCreate,
  PlanReviewEdit,
  PlanReviewList,
  PlanReviewShow,
} from "./views/planReviews/planReviews";

import {
  BlockCreate,
  BlockEdit,
  BlockList,
  BlockShow,
} from "./views/blocks/blocks";

import {
  MatchCreate,
  MatchEdit,
  MatchList,
  MatchShow,
} from "./views/matches/matches";

import Contact from "./views/custom/contact";
import Profile from "./views/custom/profile";
import ForgotPassword from "./views/authentication/forgotPassword";
import RecoverPassword from "./views/authentication/recoverPassword";
import dataProviderExtended from "./api/dataProviderExtended";
import Summaries from "./views/summaries/summaries";
import Comparison from "./views/summaries/comparison";
import { DinderMenu } from "./components/menu/menu";

const theme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      main: MAIN_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    ...defaultTheme.components,
    // RaDatagrid: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "Lavender",
    //       "& .RaDatagrid-headerCell": {
    //         backgroundColor: "MistyRose",
    //       },
    //     },
    //   },
    // },
    RaSelectInput: {
      styleOverrides: {
        root: {
          marginTop: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        variant: "outlined",
        fontSize: 24,
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
        variant: "outlined",
      },
    },
    MuiAutocomplete: { defaultProps: { fullWidth: undefined } },
    RaSimpleFormIterator: { defaultProps: { fullWidth: undefined } },
    RaTranslatableInputs: { defaultProps: { fullWidth: undefined } },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: MAIN_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    ...defaultTheme.components,
    // RaSelectInput: {
    //   styleOverrides: {
    //     root: {
    //       marginTop:0
    //     },
    //   },
    // },
  },
});

const MyAppBar = (props) => {
  const [_theme] = useTheme(theme.palette.mode);
  return (
    <AppBar {...props} color="secondary">
      <Box flex="1">
        <Typography variant="h6" id="react-admin-title"></Typography>
      </Box>
      <IconButtonWithTooltip label="contact" onClick={() => {}}>
        <a
          href="mailto:bbosch@brothapps.com"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          {_theme === "dark" ? (
            <EmailIcon htmlColor="#FFFFFF" />
          ) : (
            <EmailIcon htmlColor="#000000" />
          )}
        </a>
      </IconButtonWithTooltip>
    </AppBar>
  );
};

export const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} menu={DinderMenu}/>;



const _dataProvider = dataProviderExtended(dataProvider);

const App = () => {
  // const { permissions } = usePermissions();
  // console.log(permissions);
  
  return (
    <Admin
      dashboard={Dashboard}
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={_dataProvider}
      lightTheme={theme}
      darkTheme={darkTheme}
      layout={MyLayout}
      CustomRoutes
      disableTelemetry
    >
      <CustomRoutes noLayout>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
      </CustomRoutes>
      <CustomRoutes>
            <Route path="/summaries" element={<Summaries/>} />
      </CustomRoutes>
      <CustomRoutes>
            <Route path="/summaries/comparison" element={<Comparison/>} />
      </CustomRoutes>
      {(permissions) => (
        <>
          <Resource
            name="users"
            list={UserList}
            edit={
              permissions === "admin" || permissions === "institution"
                ? UserEdit
                : null
            }
            create={
              permissions === "admin" || permissions === "institution"
                ? UserCreate
                : null
            }
            show={UserShow}
            recordRepresentation="username"
            icon={UserIcon}
          />
           {permissions === "admin" ? (<Resource
            name="paused"
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
            show={UserShow}
            recordRepresentation="username"
            icon={PauseCircleOutlineIcon}
          />) : null }
           {permissions === "admin" ? (<Resource
            name="deleted"
            list={UserList}
            edit={
              permissions === "admin" || permissions === "institution"
                ? UserEdit
                : null
            }
            create={
              permissions === "admin" || permissions === "institution"
                ? UserCreate
                : null
            }
            show={UserShow}
            recordRepresentation="username"
            icon={HighlightOffIcon}
          />) : null }
          {permissions === "admin" ? (
            <Resource
              name="coordinators"
              list={UserList}
              edit={permissions === "admin" ? UserEdit : null}
              create={permissions === "admin" ? UserCreate : null}
              show={UserShow}
              recordRepresentation="username"
              icon={SettingsAccessibilityIcon}
            />) : null }
          {permissions === "admin" || permissions === "institution" ? (
            <Resource
              name="institutions"
              list={InstitutionList}
              edit={
                permissions === "admin" || permissions === "institution"
                  ? InstitutionEdit
                  : null
              }
              create={permissions === "admin" ? InstitutionCreate : null}
              show={InstitutionShow}
              recordRepresentation="name"
              icon={BusinessIcon}
            />
          ) : null}
          <Resource
            name="plans"
            list={PlanList}
            edit={permissions === "admin" ? PlanEdit : null}
            create={permissions === "admin" ? PlanCreate : null}
            show={PlanShow}
            recordRepresentation="name"
            icon={LocalActivityIcon}
          />
          <Resource
            name="meetings"
            list={MeetingList}
            edit={permissions === "admin" ? MeetingEdit : null}
            create={permissions === "admin" ? MeetingCreate : null}
            show={MeetingShow}
            icon={SupervisedUserCircleIcon}
          />
          <Resource
            name="reports"
            list={ReportList}
            edit={ReportEdit}
            create={ReportCreate}
            show={ReportShow}
            icon={ReportIcon}
          />
          {permissions === "admin" ? (
            <Resource
              name="questions"
              list={QuestionList}
              edit={QuestionEdit}
              create={QuestionCreate}
              show={QuestionShow}
              icon={QuizIcon}
            />
          ) : null}
          {permissions === "admin" ? (
            <Resource
              name="interests"
              list={InterestList}
              edit={InterestEdit}
              create={InterestCreate}
              show={InterestShow}
              icon={InterestsIcon}
            />
          ) : null}
          {/*permissions === "admin" ? (
            <Resource
              name="posts"
              list={ListGuesser}
              edit={EditGuesser}
              create={PostCreate}
              icon={PostIcon}
            />
          ) : null*/}
          {permissions === "admin" ? (
            <Resource
              name="notifications"
              list={NotificationList}
              edit={NotificationEdit}
              create={NotificationCreate}
              show={NotificationShow}
              icon={NotificationsIcon}
            />
          ) : null}
          {permissions === "admin" || permissions === "institution" ? (
            <Resource
              name="meeting-places"
              list={permissions === "admin" ? MeetingPlaceList : null}
              edit={permissions === "admin" ? MeetingPlaceEdit : null}
              create={permissions === "admin" ? MeetingPlaceCreate : null}
              show={MeetingPlaceShow}
              icon={PlaceIcon}
              recordRepresentation="name"
            />
          ) : null}
          <Resource
            name="user-plans"
            //list={UserPlanList}
            edit={permissions === "admin" ? UserPlanEdit : null}
            create={permissions === "admin" ? UserPlanCreate : null}
            show={UserPlanShow}
            icon={PostIcon}
          />
          <Resource
            name="meeting-reviews"
            //list={MeetingReviewList}
            edit={permissions === "admin" ? MeetingReviewEdit : null}
            create={permissions === "admin" ? MeetingReviewCreate : null}
            show={MeetingReviewShow}
            icon={PostIcon}
          />
          <Resource
            name="plan-reviews"
            //list={PlanReviewList}
            edit={permissions === "admin" ? PlanReviewEdit : null}
            create={permissions === "admin" ? PlanReviewCreate : null}
            show={PlanReviewShow}
            icon={PostIcon}
          />
          {permissions === "admin" ? (
            <Resource
              name="resources"
              list={ResourceList}
              edit={ResourceEdit}
              create={ResourceCreate}
              show={ResourceShow}
              icon={CollectionsIcon}
            />
          ) : null}
          {permissions === "admin" ? (
            <Resource
              name="matches"
              list={MatchList}
              edit={MatchEdit}
              create={MatchCreate}
              show={MatchShow}
              icon={JoinInnerIcon}
            />
          ) : null}
          {permissions === "admin" ? (
            <Resource
              name="blocks"
              list={BlockList}
              edit={BlockEdit}
              create={BlockCreate}
              show={BlockShow}
              icon={BlockIcon}
            />
          ) : null}
          {/* <CustomRoutes>
            <Route path="/contact" element={<Contact />} />
            <Route path="/profile" element={<Profile />} />
          </CustomRoutes> */}
          {/* <Resource
            name="contact"
            list={ResourceList}
            // edit={ResourceEdit}
            // create={ResourceCreate}
            // show={ResourceShow}
            icon={ContactMailIcon} 
          />*/}
        </>
      )}
    </Admin>
  );
};

export default App;

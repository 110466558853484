// in src/Item.js
import { Labeled } from "react-admin";
import Item from "../layouts/Item";

const CustomItemLabeled = ({ source, children, label, itemProps }) => {
  return (
    <Item {...itemProps}>
      <Labeled source={source} component={"h3"} label={label ? label : null}>
        {children}
      </Labeled>
    </Item>
  );
};

export default CustomItemLabeled;

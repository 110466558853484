import {
  Datagrid,
  List,
  TextField,
  EditButton,
  useRecordContext,
  TextInput,
  DateField,
  DateInput,
  NumberField,
  BooleanField,
  NumberInput,
  BooleanInput,
  Show,
  BulkExportButton,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  TranslatableFields,
  SelectInput,
  DateTimeInput,
  ReferenceInput,
  SelectField,
  usePermissions,
  FunctionField,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  SingleFieldList,
  ChipField,
  CreateButton,
  TopToolbar,
  useEditContext,
  downloadCSV,
} from "react-admin";
import { useParams } from 'react-router-dom';

import CustomCard from "../../components/cards/CustomCard";
import CustomImageField from "../../components/CustomImageField";
import CustomImageInput from "../../components/CustomImageInput";
import CustomPlacesInput from "../../components/CustomPlacesInput";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import CustomTranslatableInput from "../../components/inputs/CustomTranslatableInput";
import Column from "../../components/layouts/Column";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";
import CustomNestedList from "../../components/lists/CustomNestedList";
import config from "../../config/config";
import UserIcon from "@mui/icons-material/Group";
import * as jsonexport from "jsonexport/dist"

const PlanTitle = () => {
  const record = useRecordContext();
  return <span>Plan {record ? `"${record.title.ca}"` : ""}</span>;
};

const planFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <DateInput source={"after_startDate"} label="From start date" alwaysOn />,
  <DateInput source={"before_startDate"} label="To start date" alwaysOn />,
  <SelectInput
      source="group"
      choices={[
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
        { id: "4", name: "4" },
      ]}
      alwaysOn
    />
];

export const PlanList = () => {
  const { permissions } = usePermissions();
  const filter = {};
  const sort = { field: "startDate", order: "DESC" };
  const exporter = (records) => {

    const csv = jsonexport(records.map(record=>
      ({
        "id": record.id,
        "name": record.name,
        "users count": record.users.length,
        "users": record.users,
        "start date": record.startDate,
        "end date": record.endDate,
      })
      )).then(csv=>{
      downloadCSV(csv, 'plans');
    });
  };

  return (
    <List filters={planFilters} filter={filter} sort={sort} exporter={exporter}>
      <Datagrid rowClick="show">
        {permissions === "admin" ? <EditButton /> : null}
        {/* <TextField source="id" /> */}
        <TextField source="name" emptyText="-" />
        {/* <ReferenceField
          link="show"
          label="Coordinator"
          source="coordinator.id"
          reference="users"
          field={"coordinator.username"}
        /> */}
        <SelectField
          source="status"
          choices={[
            { id: "NOT_STARTED", name: "NOT STARTED" },
            { id: "STARTED", name: "STARTED" },
            { id: "FINISHED", name: "FINISHED" },
            { id: "COMPLETED", name: "COMPLETED" },
          ]}
          fullWidth
          emptyText="-"
        />
        <SelectField
          source="type"
          choices={[
            { id: "OCI", name: "OCI" },
            { id: "CULTURA", name: "CULTURA" },
            { id: "TALLER_FORMATIU", name: "TALLER_FORMATIU" },
            { id: "ESPORTIU", name: "ESPORTIU" },
            { id: "GASTRONOMIC", name: "GASTRONOMIC" },
            { id: "NATURA", name: "NATURA" },
          ]}
          fullWidth
          emptyText="-"
        />
        <NumberField source="group" emptyText="-" />
        <TextField source="title.ca" emptyText="-" />
        {/*<TextField source="requirementsDescription" />*/}
        <NumberField source="price" emptyText="-" />
        <CustomImageField
          source="photo"
          size="small"
          title="photo"
          sx={{ height: 60, borderRadius: 10 }}
          emptyText="-"
        />
        {/* <TextField source="location" /> */}
        <TextField source="address.ca" emptyText="-" />
        <TextField source="locationName" emptyText="-" />
        <FunctionField
          label="Users"
          render={(record) => `${record.users.length}/${record.maxUsers}`}
        />
        <DateField source="startDate" showTime emptyText="-" />
        <DateField source="endDate" showTime emptyText="-" />
        <DateField source="visibilityDate" showTime emptyText="-" />
        <BooleanField source="visibility" emptyText="-" />
        <BooleanField source="isWheelchairAccessible" emptyText="-" />
        {/*<DateField source="createdAt" />
      <DateField source="updatedAt" />*/}
      </Datagrid>
    </List>
  );
};

export const PlanEdit = () => {
  
  const { id } = useParams();

  const UserPlanListActions = (props) => {
    //const { className, basePath } = props;
    return (
      <TopToolbar>
        <CreateButton to={`/user-plans/create?plan=${id}`}/>
      </TopToolbar>
    );
  };
  
  return (
    <CustomEditForm title={<PlanTitle />}>
      <Container>
        <Column xs={12} sm={12} md={8}>
          <Row>
            <Item>
              <TextInput source="name" required />
            </Item>
            <Item>
              <SelectInput
                source="status"
                choices={[
                  { id: "NOT_STARTED", name: "NOT STARTED" },
                  { id: "STARTED", name: "STARTED" },
                  { id: "FINISHED", name: "FINISHED" },
                  { id: "COMPLETED", name: "COMPLETED" },
                ]}
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <ReferenceArrayInput
                    source="coordinators"
                    reference="users"
                    filter={{ role: "coordinator" }}
                  >
                    <AutocompleteArrayInput/>
              </ReferenceArrayInput>
            </Item>
          </Row>
          <Row>
            <Item>
              <SelectInput
                source="type"
                choices={[
                  { id: "OCI", name: "OCI" },
                  { id: "CULTURA", name: "CULTURA" },
                  { id: "TALLER_FORMATIU", name: "TALLER_FORMATIU" },
                  { id: "ESPORTIU", name: "ESPORTIU" },
                  { id: "GASTRONOMIC", name: "GASTRONOMIC" },
                  { id: "NATURA", name: "NATURA" },
                ]}
                fullWidth
              />
            </Item>
            <Item>
              <SelectInput
                source="group"
                choices={[
                  { id: "1", name: "1" },
                  { id: "2", name: "2" },
                  { id: "3", name: "3" },
                  { id: "4", name: "4" },
                ]}
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9} sx={{ marginBottom: 3 }}>
              <CustomTranslatableInput>
                <TextInput source="title" fullWidth sx={{ marginTop: 2 }} />
                <TextInput source="description" multiline rows={4} fullWidth />
                <TextInput
                  source="requirementsDescription"
                  multiline
                  rows={4}
                  fullWidth
                />
              </CustomTranslatableInput>
            </Item>
          </Row>

          <Row>
            <CustomPlacesInput source="location" required translatable={true}/>
          </Row>

          <Row>
            <Item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
              <CustomTranslatableInput>
                <TextInput source="address" fullWidth />
              </CustomTranslatableInput>
            </Item>
          </Row>

          <Row>
            <Item xs={12} sm={12} md={12}>
              <TextInput source="locationName" fullWidth />
            </Item>
          </Row>

          <Row>
            <Item xs={12} sm={12} md={12}>
              <TextInput source="googleMapsLink" fullWidth required />
            </Item>
          </Row>
          <Row>
            <Item>
              <DateTimeInput source="startDate" fullWidth required />
            </Item>
            <Item>
              <DateTimeInput source="endDate" fullWidth required />
            </Item>
            <Item>
              <DateTimeInput source="visibilityDate" fullWidth required />
            </Item>
            <Item>
              <NumberInput source="maxUsers" fullWidth />
            </Item>
            <Item>
              <NumberInput source="price" fullWidth />
            </Item>
          </Row>
          <Row>
            <Item>
              <BooleanInput source="visibility" fullWidth />
              <BooleanInput source="isWheelchairAccessible" fullWidth />
            </Item>
          </Row>
        </Column>
        <Column xs={12} sm={12} md={4}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomImageInput
                source="photo"
                label="Photo image"
                accept="image/*"
                type="DINDER_CONTENT"
              />
            </Item>
          </Row>
        </Column>
        <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="user-plans" source={"plan"} actions={<UserPlanListActions/>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <ReferenceField
                    link="show"
                    source="user.id"
                    reference="users"
                  />
                  <TextField source="status" />
                  <TextField source="reason" />
                  <BooleanField source="description" />
                  <BooleanField source="coordinatorCheck" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
      </Container>
    </CustomEditForm>
  );
};

export const PlanCreate = () => (
  <CustomCreateForm>
    <Container>
      <Column xs={12} sm={12} md={8}>
        <Row>
          <Item>
            <TextInput source="name" required />
          </Item>
          <Item>
            {/* <ReferenceInput
              label="Coordinator"
              source="coordinator.id"
              reference="users"
              filter={{ role: "coordinator" }}
              fullWidth
            /> */}
            <ReferenceArrayInput
                    source="coordinators"
                    reference="users"
                    filter={{ role: "coordinator" }}
                  >
                    <AutocompleteArrayInput/>
              </ReferenceArrayInput>
          </Item>
          <Item>
            <SelectInput
              source="status"
              choices={[
                { id: "NOT_STARTED", name: "NOT STARTED" },
                { id: "STARTED", name: "STARTED" },
                { id: "FINISHED", name: "FINISHED" },
                { id: "COMPLETED", name: "COMPLETED" },
              ]}
              fullWidth
            />
          </Item>
        </Row>
        <Row>
          <Item>
            <SelectInput
              source="type"
              choices={[
                { id: "OCI", name: "OCI" },
                { id: "CULTURA", name: "CULTURA" },
                { id: "TALLER_FORMATIU", name: "TALLER_FORMATIU" },
                { id: "ESPORTIU", name: "ESPORTIU" },
                { id: "GASTRONOMIC", name: "GASTRONOMIC" },
                { id: "NATURA", name: "NATURA" },
              ]}
              fullWidth
            />
          </Item>
          <Item>
            <SelectInput
              source="group"
              choices={[
                { id: "1", name: "1" },
                { id: "2", name: "2" },
                { id: "3", name: "3" },
                { id: "4", name: "4" },
              ]}
              fullWidth
            />
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={12} md={9} sx={{ marginBottom: 3 }}>
            <CustomTranslatableInput>
              <TextInput source="title" fullWidth sx={{ marginTop: 2 }} />
              <TextInput source="description" multiline rows={4} fullWidth />
              <TextInput
                source="requirementsDescription"
                multiline
                rows={4}
                fullWidth
              />
            </CustomTranslatableInput>
          </Item>
        </Row>

        <Row>
          <CustomPlacesInput source="location" required translatable={true} />
        </Row>

        <Row>
          <Row>
            <Item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
              <CustomTranslatableInput>
                <TextInput source="address" fullWidth required />
              </CustomTranslatableInput>
            </Item>
          </Row>
        </Row>

        <Row>
            <Item xs={12} sm={12} md={12}>
              <TextInput source="locationName" fullWidth />
            </Item>
        </Row>

        <Row>
          <Item xs={12} sm={12} md={12}>
            <TextInput source="googleMapsLink" fullWidth required />
          </Item>
        </Row>

        <Row>
          <Item>
            <DateTimeInput source="startDate" fullWidth required />
          </Item>
          <Item>
            <DateTimeInput source="endDate" fullWidth required />
          </Item>
          <Item>
            <DateTimeInput source="visibilityDate" fullWidth required />
          </Item>
          <Item>
            <NumberInput source="maxUsers" fullWidth />
          </Item>
          <Item>
            <NumberInput source="price" fullWidth />
          </Item>
          <Item>
            <SelectInput
              source="group"
              choices={[
                { id: "1", name: "1" },
                { id: "2", name: "2" },
                { id: "3", name: "3" },
                { id: "4", name: "4" },
              ]}
              fullWidth
            />
          </Item>
        </Row>
        <Row>
          <Item>
            <BooleanInput source="visibility" fullWidth />
            <BooleanInput source="isWheelchairAccessible" fullWidth />
          </Item>
        </Row>
      </Column>
      <Column xs={12} sm={12} md={4}>
        <Row>
          <Item xs={12} sm={12} md={12}>
            <CustomImageInput
              source="photo"
              label="Photo image"
              accept="image/*"
              required
            />
          </Item>
        </Row>
      </Column>
    </Container>
  </CustomCreateForm>
);

export const PlanShow = () => {
  const { id } = useParams();

  const UserPlanListActions = (props) => {
    //const { className, basePath } = props;
    return (
      <TopToolbar>
        <CreateButton to={`/user-plans/create?plan=${id}`}/>
      </TopToolbar>
    );
  };

  return (
  <Show>
    <TabbedShowLayout>
      <Tab label="summary">
        <Container>
          <Row>
            <Item>
              <CustomCard
                link={"1"}
                source="plan"
                resource="user-plans"
                label="users"
                icon={<UserIcon htmlColor="white" />}
              />
            </Item>
            <Item>
              <CustomCard
                link={"2"}
                source="plan"
                resource="plan-reviews"
                label="reviews"
                icon={<UserIcon htmlColor="white" />}
              />
            </Item>
          </Row>
          <Column sm={12} md={8}>
            <Row>
              <CustomItemLabeled>
                <TextField source="id" fullWidth emptyText="-" />
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <TextField
                  fontSize={32}
                  fontWeight={"bold"}
                  source="name"
                  fullWidth
                  emptyText="-"
                />
              </CustomItemLabeled>
            </Row>
            <Row>
              {/* <CustomItemLabeled>
                <ReferenceField
                  link="show"
                  label="Coordinator"
                  source="coordinator.id"
                  reference="users"
                  field={"coordinator.username"}
                />
              </CustomItemLabeled> */}
              <CustomItemLabeled>
                <ReferenceArrayField source="coordinators" reference="users">
                  <SingleFieldList linkType="show">
                    <ChipField source="username" emptyText="-" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </CustomItemLabeled>
              <CustomItemLabeled>
                <SelectField
                  source="status"
                  choices={[
                    { id: "NOT_STARTED", name: "NOT STARTED" },
                    { id: "STARTED", name: "STARTED" },
                    { id: "FINISHED", name: "FINISHED" },
                    { id: "COMPLETED", name: "COMPLETED" },
                  ]}
                  fullWidth
                  emptyText="-"
                />
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <SelectField
                  source="type"
                  choices={[
                    { id: "OCI", name: "OCI" },
                    { id: "CULTURA", name: "CULTURA" },
                    { id: "TALLER_FORMATIU", name: "TALLER_FORMATIU" },
                    { id: "ESPORTIU", name: "ESPORTIU" },
                    { id: "GASTRONOMIC", name: "GASTRONOMIC" },
                    { id: "NATURA", name: "NATURA" },
                  ]}
                  fullWidth
                  emptyText="-"
                />
              </CustomItemLabeled>

              <CustomItemLabeled>
                <TextField
                  fontSize={18}
                  fontWeight={"bold"}
                  source="group"
                  fullWidth
                  emptyText="-"
                />
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled
                source="title"
                itemProps={{ xs: 12, sm: 12, md: 12 }}
              >
                <TranslatableFields
                  locales={config.locales}
                  defaultLocale={config.defaultLocale}
                >
                  <TextField source="title" fullWidth emptyText="-" />
                </TranslatableFields>
              </CustomItemLabeled>
            </Row>

            <Row>
              <CustomItemLabeled
                source="description"
                itemProps={{ xs: 12, sm: 12, md: 12 }}
              >
                <TranslatableFields
                  locales={config.locales}
                  defaultLocale={config.defaultLocale}
                >
                  <TextField source="description" fullWidth emptyText="-" />
                </TranslatableFields>
              </CustomItemLabeled>
            </Row>

            <Row>
              <CustomItemLabeled
                source="requirementsDescription"
                sx={12}
                sm={12}
                md={12}
              >
                <TranslatableFields
                  locales={config.locales}
                  defaultLocale={config.defaultLocale}
                >
                  <TextField
                    source="requirementsDescription"
                    fullWidth
                    emptyText="-"
                  />
                </TranslatableFields>
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <BooleanField source="visibility" emptyText="-" />
              </CustomItemLabeled>
              <CustomItemLabeled>
                <BooleanField source="isWheelchairAccessible" emptyText="-" />
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <DateField source="createdAt" emptyText="-" />
              </CustomItemLabeled>

              <CustomItemLabeled>
                <DateField source="updatedAt" emptyText="-" />
              </CustomItemLabeled>
            </Row>
          </Column>
          <Column xs={12} sm={12} md={4}>
            <Row>
              <CustomItemLabeled itemProps={{ md: 6 }}>
                <DateField
                  fontSize={18}
                  fontWeight={"bold"}
                  source="startDate"
                  showTime
                  fullWidth
                  emptyText="-"
                />
              </CustomItemLabeled>

              <CustomItemLabeled itemProps={{ md: 6, marginBottom: 2 }}>
                <DateField
                  fontSize={18}
                  fontWeight={"bold"}
                  source="endDate"
                  showTime
                  fullWidth
                  emptyText="-"
                />
              </CustomItemLabeled>

              <CustomItemLabeled itemProps={{ md: 6, marginBottom: 2 }}>
                <DateField
                  fontSize={18}
                  fontWeight={"bold"}
                  source="visibilityDate"
                  showTime
                  fullWidth
                  emptyText="-"
                />
              </CustomItemLabeled>
            </Row>
            <Row>
              <Item xs={12} sm={12} md={12}>
                <CustomImageField
                  sx={{ width: "100%", borderRadius: 8 }}
                  source="photo"
                  size="medium"
                  title="photo"
                />
              </Item>
            </Row>
            <Row>
              <CustomItemLabeled itemProps={{ xs: 6, sm: 6, md: 6 }}>
                <NumberField source="price" fullWidth emptyText="-" />
              </CustomItemLabeled>

              <CustomItemLabeled itemProps={{ xs: 6, sm: 6, md: 6 }}>
                <NumberField source="maxUsers" fullWidth emptyText="-" />
              </CustomItemLabeled>
            </Row>
            <Row>
            <CustomItemLabeled source="address">
              <TranslatableFields
                    locales={config.locales}
                    defaultLocale={config.defaultLocale}
                  >
                    <TextField source="address" fullWidth emptyText="-" />
              </TranslatableFields>
            </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled source="locationName">
                <TextField source="locationName" fullWidth emptyText="-" />
              </CustomItemLabeled>
            </Row>
            {/* <Row>
              <Item xs={12} sm={12} md={12}>
                <CustomMapField source="location" />
              </Item>
            </Row> */}
          </Column>
        </Container>
      </Tab>
      <Tab label="users">
        <Row>
          <Item xs={12} sm={12} md={12}>
            <CustomNestedList resource="user-plans" source={"plan"} actions={<UserPlanListActions/>}>
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <ReferenceField
                  link="show"
                  source="user.id"
                  reference="users"
                  emptyText="-"
                />
                <TextField source="status" emptyText="-" />
                <TextField source="reason" emptyText="-" />
                <BooleanField source="description" />
                <BooleanField source="coordinatorCheck" />
              </Datagrid>
            </CustomNestedList>
          </Item>
        </Row>
      </Tab>
      <Tab label="reviews">
        <Row>
          <Item xs={12} sm={12} md={12}>
            <CustomNestedList resource="plan-reviews" source={"plan"}>
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <ReferenceField
                  link="show"
                  source="user.id"
                  reference="users"
                  emptyText="-"
                />
                <TextField source="activityRating" emptyText="-" />
                <TextField source="coordinatorRating" emptyText="-" />
                <BooleanField source="description" />
              </Datagrid>
            </CustomNestedList>
          </Item>
        </Row>
      </Tab>
    </TabbedShowLayout>
  </Show>
)};

import {
  ArrayInput,
  Datagrid,
  List,
  ArrayField,
  TextField,
  SingleFieldList,
  useRecordContext,
  ReferenceInput,
  ChipField,
  TextInput,
  NumberField,
  DateField,
  SimpleFormIterator,
  NumberInput,
  EditButton,
  Show,
  SimpleShowLayout,
  BulkExportButton,
} from "react-admin";
import CustomImageInput from "../../components/CustomImageInput";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import CustomTranslatableInput from "../../components/inputs/CustomTranslatableInput";
import Column from "../../components/layouts/Column";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";

const QuestionTitle = () => {
  const record = useRecordContext();
  return <span>Question {record ? `"${record.name}"` : ""}</span>;
};

const questionFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <ReferenceInput source="userId" label="User" reference="users" />,
];

export const QuestionList = () => {
  const filter = {};
  const sort = { field: "order", order: "ASC" };
  return (
    <List filters={questionFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        <EditButton />
        {/* <TextField source="id" /> */}
        <TextField source="name" emptyText="-" />
        <TextField source="text.ca" emptyText="-" />
        <ArrayField source="options" emptyText="-">
          <SingleFieldList>
            <ChipField source="label.ca" emptyText="-" />
          </SingleFieldList>
        </ArrayField>
        <NumberField source="order" emptyText="-" />
        <DateField source="createdAt" emptyText="-" />
        <DateField source="updatedAt" emptyText="-" />
      </Datagrid>
    </List>
  )
}

export const QuestionEdit = () => (
  <CustomEditForm title={<QuestionTitle />}>
    <Container>
      <Column xs={12} sm={12} md={6}>
        <Item>
          <TextInput source="name" required />
        </Item>
        <Row>
          <Item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
            <CustomTranslatableInput>
              <TextInput
                source="text"
                multiline
                rows={2}
                fullWidth
                sx={{ marginTop: 2 }}
                required
              />
            </CustomTranslatableInput>
          </Item>
        </Row>
        <Row>
          <Item>
            <NumberInput source="order" fullWidth required />
          </Item>
        </Row>
      </Column>
      <Column xs={12} sm={12} md={6}>
        <Row>
          <Item xs={12} sm={12} md={12}>
            <ArrayInput source="options" fullWidth required>
              <SimpleFormIterator>
                <TextInput source="value" sx={{ marginTop: 8 }} required />
                <TextInput source="label.ca" required />
                <TextInput source="label.es" required />
                <TextInput source="label.en" required />
                <CustomImageInput
                  source="photo"
                  label="Photo image"
                  accept="image/*"
                  required
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Item>
        </Row>
      </Column>
      {/*<Column xs={12} sm={2} md={3}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <ArrayInput source="options" fullWidth>
                <SimpleFormIterator>
                  <Row>
                    <Item xs={12} sm={12} md={12}>
                      <TextInput source="label.ca" fullWidth />
                    </Item>
                  </Row>
                  <Row>
                    <Item xs={12} sm={12} md={12}>
                      <TextInput source="label.es" fullWidth />
                    </Item>
                  </Row>
                  <Row>
                    <Item xs={12} sm={12} md={12}>
                      <TextInput source="label.en" fullWidth />
                    </Item>
                  </Row>
                  <Row>
                    <Item xs={12} sm={12} md={12}>
                      <TextInput source="value" />
                    </Item>
                  </Row>
                  <Row>
                    <Item xs={12} sm={12} md={12}>
                      <CustomImageInput
                        source="photo"
                        label="Photo image"
                        accept="image/*"
                      />
                    </Item>
                  </Row>
                </SimpleFormIterator>
              </ArrayInput>
            </Item>
          </Row>
</Column>*/}
      {/* <DateInput source="createdAt" />
      <DateInput source="updatedAt" /> */}
    </Container>
  </CustomEditForm>
);

export const QuestionCreate = () => (
  <CustomCreateForm>
    <Container>
      <Column xs={12} sm={12} md={6}>
        <Item>
          <TextInput source="name" required />
        </Item>
        <Row>
          <Item xs={12} sm={12} md={9} sx={{ marginBottom: 3 }}>
            <CustomTranslatableInput>
              <TextInput
                source="text"
                fullWidth
                sx={{ marginTop: 2 }}
                required
              />
            </CustomTranslatableInput>
          </Item>
        </Row>
        <Row>
          <Item>
            <NumberInput source="order" fullWidth required />
          </Item>
        </Row>
      </Column>
      <Column xs={12} sm={12} md={6}>
        <Row>
          <Item xs={12} sm={12} md={12}>
            <ArrayInput source="options" fullWidth required>
              <SimpleFormIterator>
                <TextInput source="value" sx={{ marginTop: 8 }} required />
                <TextInput source="label.ca" required />
                <TextInput source="label.es" required />
                <TextInput source="label.en" required />
                <CustomImageInput
                  source="photo"
                  label="Photo image"
                  accept="image/*"
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Item>
        </Row>
      </Column>
    </Container>
  </CustomCreateForm>
);

export const QuestionShow = () => (
  <Show>
    <SimpleShowLayout>
      <Container>
        <Column xs={12} sm={12} md={9}>
          <Row>
            <Row>
              <CustomItemLabeled>
                <TextField source="id" emptyText="-" />
              </CustomItemLabeled>
              <CustomItemLabeled>
                <TextField source="name" emptyText="-" />
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <TextField source="text.ca" emptyText="-" />
              </CustomItemLabeled>
              <CustomItemLabeled>
                <TextField source="text.es" emptyText="-" />
              </CustomItemLabeled>
              <CustomItemLabeled>
                <TextField source="text.en" emptyText="-" />
              </CustomItemLabeled>
            </Row>
            <CustomItemLabeled>
              <NumberField source="order" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <DateField source="createdAt" emptyText="-" />
            </CustomItemLabeled>
            <CustomItemLabeled>
              <DateField source="updatedAt" emptyText="-" />
            </CustomItemLabeled>
          </Row>
        </Column>
        <Column>
          <CustomItemLabeled>
            <ArrayField source="options" emptyText="-">
              <SingleFieldList>
                <ChipField source="label.ca" emptyText="-" />
              </SingleFieldList>
            </ArrayField>
          </CustomItemLabeled>
        </Column>
      </Container>
    </SimpleShowLayout>
  </Show>
);

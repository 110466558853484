import * as React from "react";
import Container from "../../components/layouts/Container";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Grid } from "@mui/material";

import {
  SaveButton,
  ReferenceInput,
  SimpleForm,
  Toolbar,
  usePermissions,
  TextField,
  ReferenceField,
  DateField,
  SelectField,
  BooleanField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  Datagrid,
  FunctionField,
} from "react-admin";
import { useFormContext } from 'react-hook-form';
import dataProvider from "../../api/dataProvider";
import Row from "../../components/layouts/Row";
import Item from "../../components/layouts/Item";
import CustomNestedList from "../../components/lists/CustomNestedList";
import SimpleUserCard from "../../components/users/simpleUserCard";

const Comparison = () => {

  const { permissions } = usePermissions();
  const [userA, setUserA] = React.useState();
  const [userB, setUserB] = React.useState();

  const AnalyseButton = () => {
    const { getValues } = useFormContext();
    const onClick = async e => {
      e.preventDefault();
      const { id } = getValues();
      const {data} = await dataProvider.getOne('users', { id: id });
      console.log(data);
      if (!userA) setUserA(data);
      else if (!userB) setUserB(data);
      else {
        setUserA(data);
        setUserB(null);
      }
    };
    if (!userB)
      return (
          <SaveButton type="button" onClick={onClick} icon={<SummarizeIcon/> } label="Compare" />
      );
    else
      return (
        <SaveButton type="button" onClick={onClick} icon={<SummarizeIcon/> } label="Analyse" />
      ); 
  };

  const ComparisonToolbar = () => (
    <Toolbar>
       <AnalyseButton size="large" title="test"/>
    </Toolbar>
  );

  return (
    <Container p={5}>
      <Row sx={{marginBottom:5}}>
        <SimpleForm resource="users" toolbar={<ComparisonToolbar/>}>
          <ReferenceInput
            label="Choose a user"
            source="id"
            reference="users"
            alwaysOn
          />
        </SimpleForm>
      </Row>

      <Row>
        <Grid container columns={{ xs: 4, md: 12 }}>
          {userA?<Grid item xs={6} >
            <h2>UserA</h2>
            <SimpleUserCard record={userA} />
          </Grid>:null}
          {userB?<Grid item xs={6} >
            <h2>UserB</h2>
            <SimpleUserCard record={userB} />
          </Grid>:null}
        </Grid>
      </Row>

        {userA && userB?
        <Row>
          <Row>
            <h2>Plans</h2>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="plans"  filter={{
                  "all_users": [userA?.id, userB?.id]
                  }} actions={null} perPage={1000} pagination={false} empty={<>No plans</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="name" emptyText="-" />
                <ReferenceField
                  link="show"
                  label="Coordinator"
                  source="coordinator.id"
                  reference="users"
                  field={"coordinator.username"}
                />
                <SelectField
                  source="status"
                  choices={[
                    { id: "NOT_STARTED", name: "NOT STARTED" },
                    { id: "STARTED", name: "STARTED" },
                    { id: "FINISHED", name: "FINISHED" },
                    { id: "COMPLETED", name: "COMPLETED" },
                  ]}
                  fullWidth
                  emptyText="-"
                />
                <TextField source="address.ca" emptyText="-" />
                <FunctionField
                  label="Users"
                  render={(record) => `${record.users.length}/${record.maxUsers}`}
                />
                <DateField source="startDate" showTime emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
        </Row>
        <Row>
          <h2>Matches</h2>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="matches" source={"users"} filter={{
                  "all_users": [userA?.id, userB?.id]
                  }} actions={null} perPage={1000} pagination={false}  empty={<>No matches</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" />
                  <ReferenceArrayField source="users" reference="users">
                    <SingleFieldList linkType="show">
                      <ChipField source={`username`} />
                    </SingleFieldList>
                  </ReferenceArrayField>
                  <TextField source="option" emptyText="-" />
                  <TextField source="type" emptyText="-" />
                  <TextField source="status" emptyText="-" />
                  <BooleanField source="description" fullWidth emptyText="-" />
                  <TextField source="createdAt" emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Row>
        <Row>
          <h2>Meetings</h2>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="meetings" source={"users"} filter={{
                  "all_users": [userA?.id, userB?.id]
                  }}  actions={null} perPage={1000} pagination={false}  empty={<>No meetings</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="userA.id"
                    reference="users"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="userB.id"
                    reference="users"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    label="Place"
                    source="meetingPlace.id"
                    reference="meeting-places"
                    emptyText="-"
                  />
                  <TextField source="status" emptyText="-" />
                  <TextField source="reason" emptyText="-" />
                  <BooleanField source="comment" fullWidth emptyText="-" />
                  <TextField source="date" emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
         <Row>
            <h2>{"Reports AB"}</h2>
            <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="reports" source={"userA"} filter={{
                "userA": userA?.id,
                "userB": userB?.id
              }} actions={null} perPage={1000} pagination={false}  empty={<>No reports made</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="userA.id"
                    reference="users"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="userB.id"
                    reference="users"
                    emptyText="-"
                  />
                  <TextField source="option" emptyText="-" />
                  <TextField source="type" emptyText="-" />
                  <TextField source="status" emptyText="-" />
                  <BooleanField source="description" fullWidth emptyText="-" />
                  <TextField source="createdAt" emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
           <Row>
            <h2>{"Reports BA"}</h2>
            <Row>
              <Item xs={12} sm={12} md={12}>
                <CustomNestedList resource="reports" source={"userB"} filter={{
                "userA": userB?.id,
                "userB": userA?.id
              }} actions={null} perPage={1000} pagination={false} empty={<>No reports received</>}>
                  <Datagrid rowClick="show" bulkActionButtons={false}>
                    <TextField source="id" emptyText="-" />
                    <ReferenceField
                      link="show"
                      source="userA.id"
                      reference="users"
                      emptyText="-"
                    />
                    <ReferenceField
                      link="show"
                      source="userB.id"
                      reference="users"
                      emptyText="-"
                    />
                    <TextField source="option" emptyText="-" />
                    <TextField source="type" emptyText="-" />
                    <TextField source="status" emptyText="-" />
                    <BooleanField source="description" fullWidth emptyText="-" />
                    <TextField source="createdAt" emptyText="-" />
                  </Datagrid>
                </CustomNestedList>
              </Item>
            </Row>
          </Row>
          </Row>
          {/*<Row>
            <h2>Meeting Reviews</h2>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="meeting-reviews" source={"user"} actions={null} perPage={1000} pagination={false}  empty={<>No meeting reviews</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <TextField source="answers" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="meeting.id"
                    reference="meetings"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="questionnaire.id"
                    reference="questionnaires"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="report.id"
                    reference="reports"
                    emptyText="-"
                  />
                  <DateField source="createdAt" emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Row>
        <Row>
          <h2>Plan Reviews</h2>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="plan-reviews" source={"user"} actions={null} perPage={1000} pagination={false} empty={<>No plan reviews</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}> 
                  <TextField source="id" emptyText="-" />
                  <TextField source="answers" emptyText="-" />
                  <NumberField source="activityRating" emptyText="-" />
                  <NumberField source="coordinatorRating" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="plan.id"
                    reference="plans"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="questionnaire.id"
                    reference="questionnaires"
                    emptyText="-"
                  />
                  <DateField source="createdAt" emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Row>*/}
        </Row> 
        </Row>
        :null}
    </Container>
  );
};

export default Comparison;

import {
  Datagrid,
  List,
  TextField,
  useRecordContext,
  TextInput,
  EditButton,
  Show,
  SimpleShowLayout,
  BulkExportButton,
  TranslatableFields,
} from "react-admin";
import CustomImageField from "../../components/CustomImageField";
import CustomImageInput from "../../components/CustomImageInput";
import CustomPlacesInput from "../../components/CustomPlacesInput";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomMapField from "../../components/fields/CustomMapField";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import Column from "../../components/layouts/Column";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";
import CustomTranslatableInput from "../../components/inputs/CustomTranslatableInput";
import config from "../../config/config";

const MeetingPlaceTitle = () => {
  const record = useRecordContext();
  return <span>MeetingPlace {record ? `"${record.name}"` : ""}</span>;
};

const userPlanFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const MeetingPlaceList = () => {
  const filter = {};
  const sort = { field: "_id", order: "DESC" };
  return (<List filters={userPlanFilters} filter={filter} sort={sort}>
    <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
      <EditButton />
      <TextField source="name" emptyText="-" />
      <TextField source="address.ca" emptyText="-" />
      <CustomImageField
        source="photo"
        size="small"
        title="photo"
        sx={{ height: 60, borderRadius: 10 }}
      />
    </Datagrid>
  </List>);
}

export const MeetingPlaceEdit = () => {
  return (
    <CustomEditForm title={<MeetingPlaceTitle />}>
      <Container>
        <Column xs={12} sm={12} md={8}>
          <Row>
            <Item>
              <TextInput source="name" required />
            </Item>
          </Row>
          <Row>
            <CustomPlacesInput source="location" required translatable={true}/>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
              <CustomTranslatableInput>
                <TextInput source="address" fullWidth />
              </CustomTranslatableInput>
            </Item>
          </Row>
        </Column>
        <Column xs={12} sm={12} md={4}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomImageInput
                source="photo"
                label="Photo image"
                accept="image/*"
                required
              />
            </Item>
          </Row>
        </Column>
      </Container>
    </CustomEditForm>
  );
};

export const MeetingPlaceCreate = () => {
  return (
    <CustomCreateForm>
      <Container>
        <Column xs={12} sm={12} md={8}>
          <Row>
            <Item>
              <TextInput source="name" required />
            </Item>
          </Row>
          <Row>
            <CustomPlacesInput source="location" required translatable={true}/>
          </Row>

          <Row>
            <Row>
              <Item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
                <CustomTranslatableInput>
                  <TextInput source="address" fullWidth required />
                </CustomTranslatableInput>
              </Item>
            </Row>
          </Row>
        </Column>
        <Column xs={12} sm={12} md={4}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomImageInput
                source="photo"
                label="Photo image"
                accept="image/*"
                required
              />
            </Item>
          </Row>
        </Column>
      </Container>
    </CustomCreateForm>
  );
};

export const MeetingPlaceShow = () => (
  <Show>
    <SimpleShowLayout>
      <Container>
        <Column xs={12} sm={12} md={8}>
          <Row>
            <CustomItemLabeled>
              <TextField source="name" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
          <CustomItemLabeled source="address">
            <TranslatableFields
                  locales={config.locales}
                  defaultLocale={config.defaultLocale}
                >
                   <TextField source="address" fullWidth emptyText="-" />
            </TranslatableFields>
            </CustomItemLabeled>
          </Row>
        </Column>
        <Column xs={12} sm={12} md={4}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomImageField
                sx={{ width: "100%", borderRadius: 8 }}
                source="photo"
                size="medium"
                title="photo"
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomMapField source="location" />
            </Item>
          </Row>
        </Column>
      </Container>
    </SimpleShowLayout>
  </Show>
);

import {
  Datagrid,
  List,
  TextField,
  useRecordContext,
  ReferenceInput,
  TextInput,
  DateField,
  EditButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  SelectInput,
  BulkExportButton,
  Labeled,
  BooleanField,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { useSearchParams } from 'react-router-dom';
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";

const UserPlanTitle = () => {
  const record = useRecordContext();
  return <span>UserPlan {record ? `"${record.title}"` : ""}</span>;
};

const userPlanFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const UserPlanList = () => {
  const filter = {};
  const sort = { field: "_id", order: "DESC" };
  return (
    <List filters={userPlanFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        <EditButton />
        <ReferenceField
          link="show"
          label="User"
          source="user.id"
          reference="users"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          label="Plan"
          source="plan.id"
          reference="plans"
          emptyText="-"
        />
        <TextField source="status" emptyText="-" />
        <TextField source="reason" emptyText="-" />
        <BooleanField source="coordinatorCheck" />
        {/* <DateField source="createdAt" emptyText="-" />
        <DateField source="updatedAt" emptyText="-" /> */}
      </Datagrid>
    </List>
  );
}

export const UserPlanEdit = () => {
  return (
    <CustomEditForm title={<UserPlanTitle />}>
      <Container>
        <Row>
          <Item>
            <Labeled>
              <ReferenceField
                label="User"
                source="user.id"
                reference="users"
                fullWidth
              />
            </Labeled>
          </Item>
          <Item>
            <Labeled>
              <ReferenceField
                label="Plan"
                source="plan.id"
                reference="plans"
                fullWidth
              />
            </Labeled>
          </Item>
        </Row>

        <Row>
          <Item>
            <SelectInput
              source="status"
              choices={[
                { id: "CONFIRMED", name: "CONFIRMED" },
                { id: "UNCONFIRMED", name: "UNCONFIRMED" },
                { id: "CANCELLED", name: "CANCELLED" },
                { id: "CANCELLED_48H", name: "CANCELLED_48H" },
                { id: "BLOCKED", name: "BLOCKED" },
              ]}
              fullWidth
            />
          </Item>
          <Item>
            <SelectInput
              source="reason"
              choices={[
                { id: "DISLIKE", name: "DISLIKE" },
                { id: "BAD_FEELINGS", name: "BAD_FEELINGS" },
                { id: "CANT_ATTEND", name: "CANT_ATTEND" },
                { id: "OTHER", name: "OTHER" },
              ]}
              fullWidth
            />
          </Item>
          <Item>
            <ReferenceArrayInput source="institutions" reference="institutions">
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={12} md={9}>
            <TextInput source="description" multiline rows={4} fullWidth />
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={12} md={9}>
            <BooleanInput source="coordinatorCheck" />
          </Item>
        </Row>
      </Container>
    </CustomEditForm>
  );
};

export const UserPlanCreate = () => {
  const [searchParams] = useSearchParams();
  const plan = searchParams.get('plan');
  return (
    <CustomCreateForm>
      <Container>
        <Row>
          <Item>
            <ReferenceInput label="User" source="user.id" reference="users" />
          </Item>
          <Item>
            {
              plan?
              <TextInput label="Plan" source="plan.id" reference="plans" defaultValue={plan}/>:
              <ReferenceInput label="Plan" source="plan.id" reference="plans" />
            }
          </Item>
        </Row>
        <Row>
          <Item>
            <SelectInput
              source="status"
              choices={[
                { id: "CONFIRMED", name: "CONFIRMED" },
                { id: "UNCONFIRMED", name: "UNCONFIRMED" },
                { id: "CANCELLED", name: "CANCELLED" },
                { id: "CANCELLED_48H", name: "CANCELLED_48H" },
                { id: "BLOCKED", name: "BLOCKED" },
              ]}
              defaultValue={"CONFIRMED"}
              fullWidth
            />
          </Item>
          <Item>
            <SelectInput
              source="reason"
              choices={[
                { id: "DISLIKE", name: "DISLIKE" },
                { id: "BAD_FEELINGS", name: "BAD_FEELINGS" },
                { id: "CANT_ATTEND", name: "CANT_ATTEND" },
                { id: "OTHER", name: "OTHER" },
              ]}
              fullWidth
            />
          </Item>
          {/* <Item>
            <ReferenceArrayInput source="institutions" reference="institutions">
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
          </Item> */}
        </Row>
        <Row>
          <Item xs={12} sm={12} md={9}>
            <TextInput source="description" multiline rows={4} fullWidth />
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={12} md={9}>
            <BooleanInput source="coordinatorCheck" />
          </Item>
        </Row>
      </Container>
    </CustomCreateForm>
  );
};

export const UserPlanShow = () => (
  <Show>
    <SimpleShowLayout>
      <Container>
        <Row>
          <Item>
            <Labeled>
              <TextField source="id" emptyText="-" />
            </Labeled>
          </Item>
        </Row>
        <Row>
          <Item>
            <Labeled>
              <ReferenceField
                link="show"
                source="user.id"
                reference="users"
                emptyText="-"
              />
            </Labeled>
          </Item>
          <Item>
            <Labeled>
              <ReferenceField
                link="show"
                source="plan.id"
                reference="plans"
                emptyText="-"
              />
            </Labeled>
          </Item>
        </Row>
        <Row>
          <Item>
            <Labeled>
              <TextField source="status" emptyText="-" />
            </Labeled>
          </Item>
          <Item>
            <Labeled>
              <TextField source="reason" emptyText="-" />
            </Labeled>
          </Item>
        <Row>
          <CustomItemLabeled>
            <ReferenceArrayField source="institutions" reference="institutions">
              <SingleFieldList linkType="show">
                <ChipField source="name" emptyText="-" />
              </SingleFieldList>
            </ReferenceArrayField>
          </CustomItemLabeled>
        </Row>
        </Row>
        <Row>
          <Item>
            <Labeled>
              <TextField source="description" emptyText="-" />
            </Labeled>
          </Item>
        </Row>
        <Row>
          <Item>
            <Labeled>
              <BooleanField source="coordinatorCheck" />
            </Labeled>
          </Item>
        </Row>
        <Row>
          <Item>
            <Labeled>
              <DateField source="createdAt" emptyText="-" />
            </Labeled>
          </Item>
          <Item>
            <Labeled>
              <DateField source="updatedAt" emptyText="-" />
            </Labeled>
          </Item>
        </Row>
      </Container>
    </SimpleShowLayout>
  </Show>
);

// in src/Container.js
import Grid from "@mui/material/Grid";

const Container = (props) => {
  return (
    <Grid {...props} container spacing={2} />
  );
};

export default Container;

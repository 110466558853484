import * as React from "react";
import Container from "./components/layouts/Container";
import Row from "./components/layouts/Row";
import Item from "./components/layouts/Item";
import CustomCard from "./components/cards/CustomCard";
import CustomNestedList from "./components/lists/CustomNestedList";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Grid";
import Box from "@mui/material/Grid";
import UserIcon from "@mui/icons-material/Group";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import ReportIcon from "@mui/icons-material/Report";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

import {
  BooleanField,
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
  usePermissions,
} from "react-admin";

import Dayjs from "dayjs";

const Dashboard = () => {
  const { permissions } = usePermissions();
  return (
    <Container sx={{ marginTop: 5 }}>
      <Row>
        <Item>
          <CustomCard
            resource="users"
            label="total users"
            icon={<UserIcon htmlColor="white" />}
          />
        </Item>
        <Item>
          <CustomCard
            resource="reports"
            label="reports"
            icon={<ReportIcon htmlColor="white" />}
          />
        </Item>
        <Item>
          <CustomCard
            resource="plans"
            label="plans"
            icon={<LocalActivityIcon htmlColor="white" />}
          />
        </Item>
        <Item>
          <CustomCard
            resource="meetings"
            label="meetings"
            icon={<SupervisedUserCircleIcon htmlColor="white" />}
          />
        </Item>
        <Item>
          <CustomCard
            resource="matches"
            filter= {{ status: "ACTIVE" }}
            label="Active matches"
            icon={<JoinInnerIcon htmlColor="white" />}
          />
        </Item>
        <Item>
          <CustomCard
            resource="matches"
            label="Total matches"
            icon={<JoinInnerIcon htmlColor="white" />}
          />
        </Item>
      </Row>

      <Row>
        <Item xs={12} sm={12} md={12}>
          <Box>
            <CustomNestedList
              title={"Open reports"}
              resource="reports"
              filter={{ status: "OPEN" }}
            >
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <ReferenceField
                  link="show"
                  label="User A"
                  source="userA.id"
                  reference="users"
                  field="userA.username"
                />
                <ReferenceField
                  link="show"
                  label="User B"
                  source="userB.id"
                  reference="users"
                  field="userB.username"
                />
                <TextField source="option" emptyText="-" />
                <TextField source="type" emptyText="-" />
                <TextField source="status" emptyText="-" />
                <BooleanField source="description" fullWidth emptyText="-" />
                <DateField source="createdAt" emptyText="-" />
              </Datagrid>
            </CustomNestedList>
          </Box>
        </Item>
        <Item xs={12} sm={12} md={12}>
          <Box>
            <CustomNestedList
              title={"Plans today"}
              resource="plans"
              filter={{
                before_startDate: new Dayjs().startOf("day").toDate(),
                after_startDate: new Dayjs().endOf("day").toDate(),
              }}
            >
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="name" emptyText="-" />
                <DateField source="startDate" showTime />
                <TextField source="type" emptyText="-" />
                <TextField source="status" emptyText="-" />
              </Datagrid>
            </CustomNestedList>
          </Box>
        </Item>
        <Item xs={12} sm={12} md={12}>
          <Box>
            <CustomNestedList
              title={"Next plans"}
              resource="plans"
              filter={{
                after_startDate: new Dayjs().endOf("day").toDate(),
                sort: "startDate",
              }}
            >
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="name" emptyText="-" />
                <DateField source="startDate" showTime />
                <TextField source="type" emptyText="-" />
                <TextField source="status" emptyText="-" />
              </Datagrid>
            </CustomNestedList>
          </Box>
        </Item>
      </Row>
    </Container>
  );
};

export default Dashboard;

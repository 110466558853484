import { Box } from "@mui/material";

const AuthLayout = ({ children, title='Sign in' }) => {
    return (
        <Box style={{backgroundColor:"#FF826E", display:'flex', height: '100vh', justifyContent: 'center'}}>
            <Box style={{backgroundColor:"#FF826E", marginTop:'100px', display:'flex', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <img src={require('./logo.png')} alt="logo"/>
                {<Box style={{margin:'15px', display:'flex', color:'white'}}>{title.toUpperCase()}</Box>}
                <Box style={{backgroundColor:"white", maxWidth:"300px", padding:'20px', borderRadius: '8px'}}>
                    { children }
                </Box>
            </Box>
        </Box>
    );
};

export default AuthLayout;
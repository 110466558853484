import {
  Datagrid,
  List,
  TextField,
  EditButton,
  useRecordContext,
  TextInput,
  DateField,
  Show,
  SimpleShowLayout,
  BulkExportButton,
} from "react-admin";

import { ColorField, ColorInput } from "react-admin-color-picker";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import CustomTranslatableInput from "../../components/inputs/CustomTranslatableInput";
import Column from "../../components/layouts/Column";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";

const InterestTitle = () => {
  const record = useRecordContext();
  return <span>Interest {record ? `"${record.name}"` : ""}</span>;
};

const interestFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const InterestList = () => {
  const filter = {};
  const sort = { field: "_id", order: "DESC" };
  return (
    <List filters={interestFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        <EditButton />
        {/* <TextField source="id" /> */}
        <TextField source="label.es" emptyText="-" />
        <TextField source="label.ca" emptyText="-" />
        <TextField source="label.en" emptyText="-" />
        <TextField source="value" emptyText="-" />
        <ColorField source="color" emptyText="-" />
        <DateField source="createdAt" emptyText="-" />
        <DateField source="updatedAt" emptyText="-" />
      </Datagrid>
      </List>
    );
  };

export const InterestEdit = () => (
  <CustomEditForm title={<InterestTitle />} name={"value"}>
    <Container>
      <Column xs={12} sm={12} md={10}>
        <Row>
          <Item xs={12} sm={12} md={9} sx={{ marginBottom: 3 }}>
            <CustomTranslatableInput>
              <TextInput
                source="label"
                fullWidth
                sx={{ marginTop: 2 }}
                required
              />
            </CustomTranslatableInput>
          </Item>
        </Row>
        <Row>
          <Item>
            <TextInput source="value" fullWidth required />
          </Item>
        </Row>
      </Column>
      <Column xs={12} sm={12} md={2}>
        <ColorInput source="color" />
      </Column>
    </Container>
  </CustomEditForm>
);

export const InterestCreate = () => (
  <CustomCreateForm>
    <Container>
      <Column xs={12} sm={12} md={10}>
        <Row>
          <Item>
            <TextInput source="label.ca" fullWidth required />
          </Item>
          <Item>
            <TextInput source="label.es" fullWidth required />
          </Item>
          <Item>
            <TextInput source="label.en" fullWidth required />
          </Item>
        </Row>
        <Row>
          <Item>
            <TextInput source="value" fullWidth required />
          </Item>
        </Row>
      </Column>
      <Column xs={12} sm={12} md={2}>
        <ColorInput source="color" />
      </Column>
    </Container>
  </CustomCreateForm>
);

export const InterestShow = () => (
  <Show>
    <SimpleShowLayout>
      <Container>
        <Row>
          <CustomItemLabeled>
            <TextField source="id" emptyText="-" />
          </CustomItemLabeled>
        </Row>
        <Row>
          <CustomItemLabeled>
            <TextField source="label.es" emptyText="-" />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <TextField source="label.ca" emptyText="-" />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <TextField source="label.en" emptyText="-" />
          </CustomItemLabeled>
        </Row>
        <Row>
          <CustomItemLabeled>
            <TextField source="value" emptyText="-" />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <ColorField source="color" />
          </CustomItemLabeled>
        </Row>
        <Row>
          <CustomItemLabeled>
            <DateField source="createdAt" emptyText="-" />
          </CustomItemLabeled>
          <CustomItemLabeled>
            <DateField source="updatedAt" emptyText="-" />
          </CustomItemLabeled>
        </Row>
      </Container>
    </SimpleShowLayout>
  </Show>
);

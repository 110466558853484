import {
  Datagrid,
  List,
  TextField,
  EditButton,
  useRecordContext,
  TextInput,
  DateField,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  Show,
  BulkExportButton,
  TabbedShowLayout,
  Tab,
  DateTimeInput,
  usePermissions,
  SelectField,
  ChipField,
} from "react-admin";
import CustomPlacesInput from "../../components/CustomPlacesInput";

import Container from "../../components/layouts/Container";
import Row from "../../components/layouts/Row";
import Item from "../../components/layouts/Item";
import CustomNestedList from "../../components/lists/CustomNestedList";
import CustomEditForm from "../../components/forms/CustomEditForm";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomChipField from "../../components/fields/CustomChipField";

const MeetingTitle = () => {
  const record = useRecordContext();
  return <span>Meeting {record ? `"${record.title}"` : ""}</span>;
};

const meetingFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <ReferenceInput
    label="User A"
    source="userA.id"
    reference="users"
    alwaysOn
  />,
  <ReferenceInput
    label="User B"
    source="userB.id"
    reference="users"
    alwaysOn
  />,
];

export const MeetingList = () => {
  const { permissions } = usePermissions();
  const filter = {};
  const sort = { field: "date", order: "DESC" };
  return (
    <List filters={meetingFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        {permissions === "admin" ? <EditButton /> : null}
        {/* <TextInput source="id" /> */}
        {/*    */}
        <ReferenceField
          link="show"
          label="User A"
          source="userA.id"
          reference="users"
          field="userA.username"
        />
        <ReferenceField
          link="show"
          label="Institution"
          source="userA.institution.id"
          reference="institutions"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          label="User B"
          source="userB.id"
          reference="users"
          field="userB.username"
        />
        <ReferenceField
          link="show"
          label="Institution"
          source="userB.institution.id"
          reference="institutions"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          label="Place"
          source="meetingPlace.id"
          reference="meeting-places"
          emptyText="-"
        />
        <DateField source="date" showTime emptyText="-" />
        <CustomChipField source="status" />
        <SelectField
          source="status"
          choices={[
            { id: "CONFIRMED", name: "CONFIRMED" },
            { id: "CANCELLED", name: "CANCELLED" },
            { id: "UNCONFIRMED", name: "UNCONFIRMED" },
          ]}
        />
      </Datagrid>
    </List>
  );
};

export const MeetingEdit = () => {
  return (
    <CustomEditForm title={<MeetingTitle />}>
      <Container>
        <Row>
          <Item>
            <TextInput source="title" fullWidth />
          </Item>
          <Item>
            <DateTimeInput source="date" fullWidth required />
          </Item>
          <Item>
            <SelectInput
              source="status"
              choices={[
                { id: "CONFIRMED", name: "CONFIRMED" },
                { id: "CANCELLED", name: "CANCELLED" },
                { id: "UNCONFIRMED", name: "UNCONFIRMED" },
              ]}
              fullWidth
              required
            />
          </Item>
        </Row>
        <Row>
          <Item>
            <ReferenceInput
              label="User A"
              source="userA.id"
              reference="users"
              fullWidth
              required
            />
          </Item>
          <Item>
            <ReferenceInput
              label="User B"
              source="userB.id"
              reference="users"
              fullWidth
              required
            />
          </Item>
        </Row>
        <Item>
          <ReferenceInput
            label="place"
            source="meetingPlace.id"
            reference="meeting-places"
            fullWidth
            required
          />
        </Item>
      </Container>
    </CustomEditForm>
  );
};

export const MeetingCreate = () => {
  return (
    <CustomCreateForm>
      <Container>
        <Row>
          <Item>
            <TextInput source="title" fullWidth />
          </Item>
          <Item>
            <DateTimeInput source="date" fullWidth required />
          </Item>
          <Item>
            <SelectInput
              source="status"
              choices={[
                { id: "CONFIRMED", name: "CONFIRMED" },
                { id: "CANCELLED", name: "CANCELLED" },
                { id: "UNCONFIRMED", name: "UNCONFIRMED" },
              ]}
              fullWidth
              required
            />
          </Item>
        </Row>
        <Row>
          <Item>
            <ReferenceInput
              label="User A"
              source="userA.id"
              reference="users"
              fullWidth
              required
            />
          </Item>
          <Item>
            <ReferenceInput
              label="User B"
              source="userB.id"
              reference="users"
              fullWidth
              required
            />
          </Item>
        </Row>
        <Row>
          <Item>
            <ReferenceInput
              label="place"
              source="meetingPlace.id"
              reference="meeting-places"
              fullWidth
              required
            />
          </Item>
          <CustomPlacesInput source="location" required />
        </Row>
      </Container>
    </CustomCreateForm>
  );
};

export const MeetingShow = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="summary">
        <Container>
          <Row>
            <CustomItemLabeled>
              <TextField source="id" emptyText="-" />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <TextField source="title" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <ReferenceField
                link="show"
                label="User A"
                source="userA.id"
                reference="users"
                field="userA.username"
              />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <ReferenceField
                link="show"
                label="Institution"
                source="userA.institution.id"
                reference="institutions"
                emptyText="-"
              />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <ReferenceField
                link="show"
                label="User B"
                source="userB.id"
                reference="users"
                field="userB.username"
              />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <ReferenceField
                link="show"
                label="Institution"
                source="userB.institution.id"
                reference="institutions"
                emptyText="-"
              />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <DateField source="date" showTime emptyText="-" />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <TextField source="status" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <ReferenceField
                link="show"
                label="Place"
                source="meetingPlace.id"
                reference="meeting-places"
                emptyText="-"
              />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <DateField source="createdAt" emptyText="-" />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <DateField source="updatedAt" emptyText="-" />
            </CustomItemLabeled>
          </Row>
        </Container>
      </Tab>
      <Tab label="reviews">
        <Row>
          <Item xs={12} sm={12} md={12}>
            <CustomNestedList resource="meeting-reviews" source={"meeting"}>
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <ReferenceField
                  link="show"
                  source="user.id"
                  reference="users"
                  emptyText="-"
                />
                <ReferenceField
                  link="show"
                  source="questionnaire.id"
                  reference="questionnaires"
                  emptyText="-"
                />
                <ReferenceField
                  link="show"
                  source="report.id"
                  reference="reports"
                  emptyText="-"
                />
                <TextField source="answers" emptyText="-" />
                <TextField source="description" emptyText="-" />
              </Datagrid>
            </CustomNestedList>
          </Item>
        </Row>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

import {
  Datagrid,
  List,
  TextField,
  useRecordContext,
  DateField,
  EditButton,
  Show,
  SimpleShowLayout,
  BulkExportButton,
  BooleanInput,
  DateInput,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  RadioButtonGroupInput,
  SelectInput,
} from "react-admin";
import CustomImageField from "../../components/CustomImageField";
import CustomImageInput from "../../components/CustomImageInput";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import Column from "../../components/layouts/Column";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";
import CustomChipField from "../../components/fields/CustomChipField";
import SimpleArrayField from "../../components/fields/CustomSimpleArrayField";

const ResourceTitle = () => {
  const record = useRecordContext();
  return <span>Resource {record ? `"${record.title}"` : ""}</span>;
};

const reportFilters = [
  <DateInput source="reviewDate" alwaysOn />,
  // <BooleanInput
  //   defaultValue={false}
  //   defaultChecked={false}
  //   source="visibility"
  //   alwaysOn
  // />,
  <SelectInput
    source="status"
    choices={[
      { id: "PENDING", name: "PENDING" },
      { id: "ACCEPTED", name: "ACCEPTED" },
      { id: "REJECTED", name: "REJECTED" },
    ]}
    alwaysOn
  />,
];

const STATUS_AND_MODERATION_RESULT_COLORS = {
  PENDING: {
    label: "PENDING",
    color: "info",
  },
  ACCEPTED: {
    label: "ACCEPTED",
    color: "success",
  },
  REJECTED: {
    label: "REJECTED",
    color: "error",
  },
  WARNING: {
    label: "WARNING",
    color: "warning",
  },
};

export const ResourceList = () => {
  const filter = {};
  const sort = { field: "createdAt", order: "DESC" };
  return (
    <List filters={reportFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        <EditButton />
        <CustomImageField
          source="path"
          size="small"
          title="res"
          sx={{ borderRadius: 20, width: 40, height: 40 }}
          emptyText="-"
        />
        <ReferenceField
          link="show"
          source="user.id"
          reference="users"
          emptyText="-"
          fullWidth
        />
        <CustomChipField source="status" data={STATUS_AND_MODERATION_RESULT_COLORS}/>
        <CustomChipField source="moderationResult" data={STATUS_AND_MODERATION_RESULT_COLORS}/>
        <DateField source="reviewDate" emptyText="-" />
        <DateField source="createdAt" emptyText="-" />
        <DateField source="updatedAt" emptyText="-" />
      </Datagrid>
    </List>
  );
}
  
export const ResourceEdit = () => {
  return (
    <CustomEditForm title={<ResourceTitle />} showDelete>
      <Container>
        <Column xs={12} sm={12} md={8}>
          <Row>
            <Item>
              <CustomImageInput source="path" fullWidth required />
            </Item>
          </Row>
          <Row>
            <Item>
              <ReferenceInput
                link="show"
                label="User"
                source="user.id"
                reference="users"
                fullWidth
              />
            </Item>
          </Row>
          <Row>
            <Item sx={12} sm={12} md={9}>
              <BooleanInput source="visibility" fullWidth />
            </Item>
          </Row>
          <Row>
            <Item sx={12} sm={12} md={9}>
              <RadioButtonGroupInput
                source="status"
                choices={[
                  { id: "ACCEPTED", name: "Accept" },
                  { id: "REJECTED", name: "Reject" },
                ]}
              />
            </Item>
          </Row>
        </Column>
        <Column xs={12} sm={12} md={4}>
          <Row>
              <CustomItemLabeled>
                <CustomChipField source="moderationResult" data={STATUS_AND_MODERATION_RESULT_COLORS}/>
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <SimpleArrayField source="moderationLabels" emptyText="-" />
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <SimpleArrayField source="labels" emptyText="-" />
              </CustomItemLabeled>
            </Row>
        </Column>
      </Container>
    </CustomEditForm>
  );
};

export const ResourceCreate = () => {
  return (
    <CustomCreateForm>
      <Container>
        <Row>
          <Item>
            <CustomImageInput source="path" fullWidth required />
          </Item>
        </Row>
        <Row>
          <Item>
            <ReferenceInput
              link="show"
              label="User"
              source="user.id"
              reference="users"
              fullWidth
            />
          </Item>
        </Row>
        <Row>
          <Item sx={12} sm={12} md={9}>
            <BooleanInput source="visibility" fullWidth />
          </Item>
        </Row>
      </Container>
    </CustomCreateForm>
  );
};

export const ResourceShow = () => (
  <Show>
    <SimpleShowLayout>
      <Container>
        <Column xs={12} sm={12} md={8}>
          <Row>
            <CustomItemLabeled>
              <TextField source="id" emptyText="-" />
            </CustomItemLabeled>
            <CustomItemLabeled>
              <ReferenceField
                link="show"
                source="user.id"
                reference="users"
                emptyText="-"
              />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <CustomChipField source="status" data={STATUS_AND_MODERATION_RESULT_COLORS}/>
            </CustomItemLabeled>
            <CustomItemLabeled>
              <DateField source="reviewDate" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <DateField source="createdAt" emptyText="-" />
            </CustomItemLabeled>
            <CustomItemLabeled>
              <DateField source="updatedAt" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <CustomChipField source="moderationResult" data={STATUS_AND_MODERATION_RESULT_COLORS}/>
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <SimpleArrayField source="moderationLabels" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <SimpleArrayField source="labels" emptyText="-" />
            </CustomItemLabeled>
          </Row>
        </Column>
        <Column xs={12} sm={12} md={4}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomImageField
                sx={{ width: "100%" }}
                source="path"
                size="medium"
              />
            </Item>
          </Row>
        </Column>
      </Container>
    </SimpleShowLayout>
  </Show>
);

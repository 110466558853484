import { ReferenceInput } from 'react-admin';

const CustomReferenceInput = (props) => {
  return (
    <div style={{marginBottom:4}}>
      <ReferenceInput {...props}></ReferenceInput>
    </div>
  );
};

export default CustomReferenceInput;
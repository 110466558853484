import {
  Datagrid,
  List,
  TextField,
  useRecordContext,
  ReferenceInput,
  TextInput,
  DateField,
  EditButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  BulkExportButton,
  ArrayField,
} from "react-admin";
import CustomAnswersField from "../../components/fields/CustomAnswersField";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";

const PlanReviewTitle = () => {
  const record = useRecordContext();
  return <span>PlanReview {record ? `"${record.title}"` : ""}</span>;
};

const userPlanFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const PlanReviewList = () => {
  const filter = {};
  const sort = { field: "_id", order: "DESC" };
  return (
    <List filters={userPlanFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        <EditButton />
        <ReferenceField
          link="show"
          source="user.id"
          reference="users"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          source="plan.id"
          reference="plans"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          source="questionnaire.id"
          reference="questionnaires"
          emptyText="-"
        />
        <ArrayField source="answers" emptyText="-" />
        <TextField source="activityRating" emptyText="-" />
        <TextField source="coordinatorRating" emptyText="-" />
        <TextField source="description" emptyText="-" />
      </Datagrid>
    </List>
  );
}
  
export const PlanReviewEdit = () => {
  return (
    <CustomEditForm title={<PlanReviewTitle />} showDelete>
      <Container>
        <Row>
          <Item>
            <ReferenceInput source="user.id" reference="users" fullWidth />
          </Item>
          <Item>
            <ReferenceInput source="plan.id" reference="plans" fullWidth />
          </Item>
          <Item>
            <ReferenceInput
              source="questionnaire.id"
              reference="questionnaires"
              fullWidth
            />
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={12} md={9}>
            <TextInput source="description" multiline rows={4} fullWidth />
          </Item>
        </Row>
      </Container>
    </CustomEditForm>
  );
};

export const PlanReviewCreate = () => {
  return (
    <CustomCreateForm>
      <Container>
        <Row>
          <Item>
            <ReferenceInput source="user.id" reference="users" fullWidth />
          </Item>
          <Item>
            <ReferenceInput source="plan.id" reference="plans" fullWidth />
          </Item>
          <Item>
            <ReferenceInput
              source="questionnaire.id"
              reference="questionnaires"
              fullWidth
            />
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={12} md={9}>
            <TextInput source="description" multiline rows={4} fullWidth />
          </Item>
        </Row>
      </Container>
    </CustomCreateForm>
  );
};

export const PlanReviewShow = () => (
  <Show>
    <SimpleShowLayout>
      <Container>
        <Row>
          <CustomItemLabeled>
            <TextField source="id" emptyText="-" />
          </CustomItemLabeled>
        </Row>
        <Row>
          <CustomItemLabeled>
            <ReferenceField
              link="show"
              source="user.id"
              reference="users"
              emptyText="-"
            />
          </CustomItemLabeled>

          <CustomItemLabeled>
            <ReferenceField
              link="show"
              source="plan.id"
              reference="plans"
              emptyText="-"
            />
          </CustomItemLabeled>

          <CustomItemLabeled>
            <ReferenceField
              link="show"
              source="questionnaire.id"
              reference="questionnaires"
              emptyText="-"
            />
          </CustomItemLabeled>
        </Row>
        <Row>
          <CustomItemLabeled>
            <TextField source="activityRating" emptyText="-" />
          </CustomItemLabeled>

          <CustomItemLabeled>
            <TextField source="coordinatorRating" emptyText="-" />
          </CustomItemLabeled>
        </Row>
        <Row>
          <CustomItemLabeled itemProps={{ sx: 12, sm: 12, md: 12 }}>
            <CustomAnswersField source="answers" />
          </CustomItemLabeled>
        </Row>
        {/* <Row>
          <CustomItemLabeled>
            <ArrayField source="answer" emptyText="-" />
          </CustomItemLabeled>
        </Row> */}
        <Row>
          <CustomItemLabeled>
            <TextField source="description" emptyText="-" />
          </CustomItemLabeled>
        </Row>
        <Row>
          <CustomItemLabeled>
            <DateField source="createdAt" emptyText="-" />
          </CustomItemLabeled>

          <CustomItemLabeled>
            <DateField source="updatedAt" emptyText="-" />
          </CustomItemLabeled>
        </Row>
      </Container>
    </SimpleShowLayout>
  </Show>
);

import {
    Create,
    SimpleForm,
  } from "react-admin";
  
  const CustomCreateForm = (props) => {
    const transform = (data) => {
      const sanitizedData = {};
      for (const key in data) {
        if (typeof data[key] === "string" && data[key].trim().length === 0)
          continue;
        sanitizedData[key] = data[key];
      }
      return sanitizedData;
    };
    return (
      <Create transform={transform}>
        <SimpleForm
          warnWhenUnsavedChanges
        >{props.children}</SimpleForm>
      </Create>
    );
  };
  
  export default CustomCreateForm;
  
import { Edit, SimpleForm } from "react-admin";
import CustomEditToolbar from "../toolbars/CustomEditToolbar";

const CustomEditForm = (props) => {
  const transform = (data) => {
    const sanitizedData = {};
    for (const key in data) {
      if (typeof data[key] === "string" && data[key].trim().length === 0)
        continue;
      sanitizedData[key] = data[key];
    }
    return sanitizedData;
  };
  return (
    <Edit title={props.title} transform={transform}>
      <SimpleForm
        toolbar={
          <CustomEditToolbar name={props.name} showDelete={props.showDelete} />
        }
        warnWhenUnsavedChanges
      >
        {props.children}
      </SimpleForm>
    </Edit>
  );
};

export default CustomEditForm;

import {
  Datagrid,
  List,
  TextField,
  useRecordContext,
  DateField,
  Show,
  SimpleShowLayout,
  BulkExportButton,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  SelectInput,
} from "react-admin";

import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import Row from "../../components/layouts/Row";

const MatchTitle = () => {
  const record = useRecordContext();
  return <span>Match {record ? `"${record.name}"` : ""}</span>;
};

const matchFilters = [
  <SelectInput
    source="status"
    choices={[
      { id: "ACTIVE", name: "ACTIVE"},
      { id: "UNMATCHED", name: "UNMATCHED"},
    ]}
    alwaysOn
  />,
];

export const MatchList = () => {
  const filter = {};
  const sort = { field: "_id", order: "DESC" };
  return (
    <List filters={matchFilters} filter={filter} sort={sort} actions={null}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        {/* <EditButton /> */}
        {/* <TextField source="id" /> */}
        <ReferenceArrayField label="Users" reference="users" source="users">
          <SingleFieldList>
              <ChipField source="username" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="status" emptyText="-" />
        <DateField source="createdAt" emptyText="-" />
        <DateField source="updatedAt" emptyText="-" />
      </Datagrid>
      </List>
    );
  };

export const MatchEdit = () => (
  <CustomEditForm title={<MatchTitle />} name={"value"}>
    <Container>
      <Row>
        <CustomItemLabeled>
          <ReferenceArrayField label="Users" reference="users" source="users">
            <SingleFieldList>
                <ChipField source="username" />
            </SingleFieldList>
          </ReferenceArrayField>
        </CustomItemLabeled>
      </Row>
      <Row>
        <Item>
          <SelectInput
            source="status"
            choices={[
              { id: "ACTIVE", name: "ACTIVE"},
              { id: "UNMATCHED", name: "UNMATCHED"},
            ]}
            fullWidth
          />
        </Item>
      </Row>
      <CustomItemLabeled>
        <DateField source="createdAt" emptyText="-" />
      </CustomItemLabeled>
      <CustomItemLabeled>
        <DateField source="updatedAt" emptyText="-" />
      </CustomItemLabeled>
    </Container>
  </CustomEditForm>
);

export const MatchCreate = () => (
  <CustomCreateForm>
    <Container>
      
    </Container>
  </CustomCreateForm>
);

export const MatchShow = () => (
  <Show>
    <SimpleShowLayout>
      <Container>
      <Row>
        <CustomItemLabeled>
          <ReferenceArrayField label="Users" reference="users" source="users">
            <SingleFieldList>
                <ChipField source="username" />
            </SingleFieldList>
          </ReferenceArrayField>
        </CustomItemLabeled>
      </Row>
      <Row>
        <CustomItemLabeled>
          <TextField source="status" emptyText="-" />
        </CustomItemLabeled>
      </Row>
      <CustomItemLabeled>
        <DateField source="createdAt" emptyText="-" />
      </CustomItemLabeled>
      <CustomItemLabeled>
        <DateField source="updatedAt" emptyText="-" />
      </CustomItemLabeled>
      </Container>
    </SimpleShowLayout>
  </Show>
);

import React from 'react';
import { Button, TextInput, useLogin, useNotify, Form, PasswordInput, Link } from 'react-admin';
import LoginIcon from '@mui/icons-material/Login';
// in src/Item.js
import {
    Box,
  } from "@mui/material";
import AuthLayout from './authLayout';

const LoginPage = () => {

    const login = useLogin();
    const notify = useNotify();
    
    const submit = async (data) => login(data).catch(() => { notify("Invalid email or password", { type: 'error' }); });    
        
    return (  
        <AuthLayout>
            <Form onSubmit={submit}>
                <TextInput source="username" fullWidth  autoComplete="username"/>
                <PasswordInput source="password" fullWidth autoComplete='current-password' />
                <Box style={{marginBottom:'10px', display:'flex', color:'white', alignItems: 'center'}}>
                    <Button type="submit" label="Sign in"><LoginIcon></LoginIcon></Button>
                    <Link to="/forgot-password" style={{ flex: 1, textAlign: 'end'}}>Forgot your password?</Link>
                </Box>
            </Form>
        </AuthLayout>
    );
};

export default LoginPage;
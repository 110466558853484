import { TranslatableInputs } from "react-admin";
import config from "../../config/config";

const CustomTranslatableInput = (props) => {
  return (
    <TranslatableInputs locales={config.locales} defaultLocale={config.defaultLocale} style={{marginBottom: 2}}>
      {props.children}
    </TranslatableInputs>
  );
};

export default CustomTranslatableInput;

import {
  Datagrid,
  List,
  TextField,
  EditButton,
  useRecordContext,
  TextInput,
  DateField,
  Show,
  BulkExportButton,
  SelectInput,
  SimpleShowLayout,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceField,
  ReferenceInput,
} from "react-admin";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomEditForm from "../../components/forms/CustomEditForm";
import CustomTranslatableInput from "../../components/inputs/CustomTranslatableInput";
import Column from "../../components/layouts/Column";
import Container from "../../components/layouts/Container";
import Item from "../../components/layouts/Item";
import Row from "../../components/layouts/Row";

const NotificationTitle = () => {
  const record = useRecordContext();
  return <span>Notification {record ? `"${record.name}"` : ""}</span>;
};

const planFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const NotificationList = () => {
  const filter = {};
  const sort = { field: "_id", order: "DESC" };
  return (
    <List filters={planFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        <EditButton />
        <TextField source="title.es" emptyText="-" />
        <TextField source="type" emptyText="-" />
        <ReferenceField
          link="show"
          source="meeting.id"
          reference="meetings"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          source="plan.id"
          reference="plans"
          emptyText="-"
        />
        <ReferenceField
          link="show"
          source="report.id"
          reference="reports"
          emptyText="-"
        />
        <DateField source="createdAt" emptyText="-" />
        <DateField source="updatedAt" emptyText="-" />
      </Datagrid>
    </List>
  );
}

export const NotificationEdit = () => {
  return (
    <CustomEditForm title={<NotificationTitle />}>
      <Container>
        <Column xs={12} sm={12} md={12}>
          <Row>
            <Item>
              <TextInput source="name" />
            </Item>
          </Row>
          <Row>
            <Item>
              <SelectInput
                source="type"
                choices={[
                  { id: "NEW_MATCH", name: "NEW_MATCH" },
                  { id: "NEW_PLAN", name: "NEW_PLAN" },
                  { id: "MEETING_REMINDER", name: "MEETING_REMINDER" },
                  { id: "PLAN_REMINDER", name: "PLAN_REMINDER" },
                  { id: "MEETING_CANCELLED", name: "MEETING_CANCELLED" },
                  { id: "REPORT_ABUSE", name: "REPORT_ABUSE" },
                ]}
                fullWidth
                required
              />
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9}>
              <ReferenceArrayInput source="receivers" reference="users">
                <SelectArrayInput optionText="username" fullWidth />
              </ReferenceArrayInput>
            </Item>
          </Row>
          <Row>
            <Item xs={12} sm={12} md={9} sx={{ marginBottom: 3 }}>
              <CustomTranslatableInput>
                <TextInput source="title" />
                <TextInput source="text" rows={4} multiline fullWidth />
              </CustomTranslatableInput>
            </Item>
          </Row>
          <Row>
            <Item>
              <ReferenceInput
                label="meeting"
                source="meeting.id"
                reference="meetings"
              />
            </Item>
            <Item>
              <ReferenceInput label="plan" source="plan.id" reference="plans" />
            </Item>
            <Item>
              <ReferenceInput
                label="report"
                source="report.id"
                reference="reports"
              />
            </Item>
          </Row>
        </Column>
      </Container>
    </CustomEditForm>
  );
};

export const NotificationCreate = () => (
  <CustomCreateForm title={<NotificationTitle />}>
    <Container>
      <Column xs={12} sm={12} md={12}>
        <Row>
          <Item>
            <TextInput source="name" />
          </Item>
        </Row>
        <Row>
          <Item>
            <SelectInput
              source="type"
              choices={[
                { id: "NEW_MATCH", name: "NEW_MATCH" },
                { id: "NEW_PLAN", name: "NEW_PLAN" },
                { id: "MEETING_REMINDER", name: "MEETING_REMINDER" },
                { id: "PLAN_REMINDER", name: "PLAN_REMINDER" },
                { id: "MEETING_CANCELLED", name: "MEETING_CANCELLED" },
                { id: "REPORT_ABUSE", name: "REPORT_ABUSE" },
              ]}
              fullWidth
              required
            />
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={12} md={9}>
            <ReferenceArrayInput source="receivers" reference="users">
              <SelectArrayInput optionText="username" fullWidth />
            </ReferenceArrayInput>
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={12} md={9} sx={{ marginBottom: 3 }}>
            <CustomTranslatableInput>
              <TextInput source="title" />
              <TextInput source="text" rows={4} multiline fullWidth />
            </CustomTranslatableInput>
          </Item>
        </Row>

        <Row>
          <Item>
            <ReferenceInput
              label="meeting"
              source="meeting.id"
              reference="meetings"
            />
          </Item>
          <Item>
            <ReferenceInput label="plan" source="plan.id" reference="plans" />
          </Item>
          <Item>
            <ReferenceInput
              label="report"
              source="report.id"
              reference="reports"
            />
          </Item>
        </Row>
      </Column>
    </Container>
  </CustomCreateForm>
);

export const NotificationShow = () => (
  <Show>
    <SimpleShowLayout>
      <Container>
        <Column sx={12} sm={12} md={12}>
          <Row>
            <CustomItemLabeled>
              <TextField source="id" fullWidth emptyText="-" />
            </CustomItemLabeled>
            <CustomItemLabeled>
              <TextField source="name" emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <TextField source="type" fullWidth emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <TextField source="title.ca" fullWidth emptyText="-" />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <TextField source="title.es" fullWidth emptyText="-" />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <TextField source="title.en" fullWidth emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <TextField source="text.ca" fullWidth emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <TextField source="text.es" fullWidth emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <TextField source="text.en" fullWidth emptyText="-" />
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <ReferenceArrayField source="receivers" reference="users">
                <SingleFieldList linkType="show">
                  <ChipField source="username" emptyText="-" />
                </SingleFieldList>
              </ReferenceArrayField>
            </CustomItemLabeled>
          </Row>
          <Row>
            <CustomItemLabeled>
              <ReferenceField
                link="show"
                source="meeting.id"
                reference="meetings"
                emptyText="-"
              />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <ReferenceField
                link="show"
                source="plan.id"
                reference="plans"
                emptyText="-"
              />
            </CustomItemLabeled>

            <CustomItemLabeled>
              <ReferenceField
                link="show"
                source="report.id"
                reference="reports"
                emptyText="-"
              />
            </CustomItemLabeled>
          </Row>
        </Column>
      </Container>
    </SimpleShowLayout>
  </Show>
);

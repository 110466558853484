import {
  Datagrid,
  List,
  TextField,
  EditButton,
  useRecordContext,
  TextInput,
  EmailField,
  DateField,
  Show,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  BulkExportButton,
  Tab,
  TabbedShowLayout,
  SingleFieldList,
  ChipField,
  usePermissions,
  UrlField,
  useGetIdentity,
  TranslatableFields,
  useShowContext,
  TopToolbar,
  AutocompleteArrayInput,
} from "react-admin";
import CustomImageField from "../../components/CustomImageField";
import CustomImageInput from "../../components/CustomImageInput";
import CustomPlacesInput from "../../components/CustomPlacesInput";

import Container from "../../components/layouts/Container";
import Column from "../../components/layouts/Column";
import Row from "../../components/layouts/Row";
import Item from "../../components/layouts/Item";
import CustomTranslatableInput from "../../components/inputs/CustomTranslatableInput";
import CustomNestedList from "../../components/lists/CustomNestedList";
import CustomEditForm from "../../components/forms/CustomEditForm";
import CustomCreateForm from "../../components/forms/CustomCreateForm";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import CustomMapField from "../../components/fields/CustomMapField";
import CustomCard from "../../components/cards/CustomCard";

import UserIcon from "@mui/icons-material/Group";
import config from "../../config/config";

const InstitutionTitle = () => {
  const record = useRecordContext();
  return <span>Institution {record ? `"${record.name}"` : ""}</span>;
};

const institutionFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const InstitutionList = () => {
  const { permissions } = usePermissions();
  // const { identity } = useGetIdentity();
  const filter = {};
  const sort = { field: "_id", order: "DESC" };
  // if (permissions === "institution" && identity.institution !== "null") {
  //   filter.ids = identity.institution;
  // }
  return (
    <List filters={institutionFilters} filter={filter} sort={sort}>
      <Datagrid rowClick="show" bulkActionButtons={<BulkExportButton />}>
        {permissions === "admin" ? <EditButton /> : null}
        {/* <TextInput source="id" /> */}
        <CustomImageField
          source="logo"
          size="small"
          title="logo"
          sx={{ borderRadius: 20, width: 40, height: 40 }}
        />
        <TextField source="name" emptyText="-" />
        {/*<TextField source="description" />*/}
        <EmailField source="email" emptyText="-" />
        <TextField source="phonenumber" emptyText="-" />
        <TextField source="address" emptyText="-" />
        {/*<TextField source="location" />*/}
        {/*<TextField source="admins" />*/}
        {/* <DateField source="createdAt" />
        <DateField source="updatedAt" /> */}
      </Datagrid>
    </List>
  );
};

export const InstitutionEdit = () => {
  return (
    <CustomEditForm title={<InstitutionTitle />} name={"name"}>
      <Container>
        <Column xs={12} sm={12} md={9}>
          <Row>
            <Item>
              <TextInput source="name" fullWidth required />
            </Item>
            <Item>
              <TextInput source="email" fullWidth required />
            </Item>
            <Item>
              <TextInput source="phonenumber" fullWidth />
            </Item>
          </Row>

          <Row>
            <Item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
              <CustomTranslatableInput>
                <TextInput
                  sx={{ marginTop: 2 }}
                  multiline
                  source="description"
                  rows={4}
                  fullWidth
                />
              </CustomTranslatableInput>
            </Item>
          </Row>

          <Row>
            <CustomPlacesInput source="location" />
          </Row>
          <Row>
            <Item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
              <TextInput source="address" fullWidth />
            </Item>
          </Row>

          <Row>
            <Item>
              <TextInput source="province" fullWidth />
            </Item>
            <Item>
              <TextInput source="nif" fullWidth />
            </Item>
            <Item>
              <TextInput source="website" fullWidth />
            </Item>
          </Row>

          <Row>
            <Item xs={12} sm={12} md={9}>
              <ReferenceArrayInput
                source="admins"
                reference="users"
                filter={{ role: "institution" }}
              >
                {/* <SelectArrayInput optionText="username" fullWidth /> */}
                <AutocompleteArrayInput/>
              </ReferenceArrayInput>
            </Item>
          </Row>
        </Column>
        <Column xs={12} sm={12} md={3}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomImageInput
                source="logo"
                label="Logo image"
                accept="image/*"
              />
            </Item>
          </Row>
        </Column>
      </Container>
    </CustomEditForm>
  );
};

export const InstitutionCreate = () => {
  return (
    <CustomCreateForm>
      <Container>
        <Column xs={12} sm={12} md={9}>
          <Row>
            <Item>
              <TextInput source="name" fullWidth required />
            </Item>
            <Item>
              <TextInput source="email" fullWidth required />
            </Item>
            <Item>
              <TextInput source="phonenumber" fullWidth />
            </Item>
          </Row>

          <Row>
            <Item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
              <CustomTranslatableInput>
                <TextInput
                  sx={{ marginTop: 2 }}
                  multiline
                  source="description"
                  rows={4}
                  fullWidth
                />
              </CustomTranslatableInput>
            </Item>
          </Row>

          <Row>
            <CustomPlacesInput source="location" />
          </Row>
          <Row>
            <Item xs={12} sm={12} md={12} sx={{ marginBottom: 3 }}>
              <TextInput source="address" fullWidth />
            </Item>
          </Row>

          <Row>
            <Item>
              <TextInput source="province" fullWidth />
            </Item>
            <Item>
              <TextInput source="nif" fullWidth />
            </Item>
            <Item>
              <TextInput source="website" fullWidth />
            </Item>
          </Row>

          <Row>
            <Item xs={12} sm={12} md={9}>
              <ReferenceArrayInput
                source="admins"
                reference="users"
                filter={{ role: "institution" }}
              >
                <SelectArrayInput optionText="username" fullWidth />
              </ReferenceArrayInput>
            </Item>
          </Row>
        </Column>
        <Column xs={12} sm={12} md={3}>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomImageInput
                source="logo"
                label="Logo image"
                accept="image/*"
              />
            </Item>
          </Row>
        </Column>
      </Container>
    </CustomCreateForm>
  );
};

const InstitutionShowActions = () => {
  const { permissions } = usePermissions();
  const { record } = useShowContext();
  const { identity } = useGetIdentity();
  let hasPermissions = false;
  if (
    permissions === "admin" ||
    (permissions === "institution" && identity.institution === record.id)
  ) {
    hasPermissions = true;
  }
  return <TopToolbar>{hasPermissions ? <EditButton /> : null}</TopToolbar>;
};

export const InstitutionShow = () => (
  <Show actions={<InstitutionShowActions />}>
    <TabbedShowLayout>
      <Tab label={"summary"}>
        <Container>
          <Row>
            <Item>
              <CustomCard
                link={"1"}
                source="institution"
                resource="users"
                label="users"
                icon={<UserIcon htmlColor="white" />}
              />
            </Item>
            {/* <Item>
              <CustomCard
                source="institution"
                resource="meetings"
                label="meetings"
                icon={<UserIcon htmlColor="white" />}
              />
            </Item>
            <Item>
              <CustomCard
                source="institution"
                resource="user-plans"
                label="user plans"
                icon={<UserIcon htmlColor="white" />}
              />
            </Item> */}
          </Row>
          <Column sx={12} sm={12} md={9}>
            <Row>
              <CustomItemLabeled>
                <TextField source="name" emptyText="-" />
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <EmailField source="email" emptyText="-" />
              </CustomItemLabeled>

              <CustomItemLabeled>
                <TextField source="phonenumber" emptyText="-" />
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <TranslatableFields
                  locales={config.locales}
                  defaultLocale={config.defaultLocale}
                >
                  <TextField source="description" fullWidth emptyText="-" />
                </TranslatableFields>
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <TextField source="address" emptyText="-" />
              </CustomItemLabeled>

              {/* <CustomItemLabeled>
                <TextField source="location" emptyText="-" />
              </CustomItemLabeled> */}
            </Row>
            <Row>
              <CustomItemLabeled>
                <TextField source="province" emptyText="-" />
              </CustomItemLabeled>

              <CustomItemLabeled>
                <TextField source="nif" emptyText="-" />
              </CustomItemLabeled>

              <CustomItemLabeled>
                <UrlField source="website" emptyText="-" />
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <ReferenceArrayField source="admins" reference="users">
                  <SingleFieldList linkType="show">
                    <ChipField source="username" emptyText="-" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <DateField source="createdAt" emptyText="-" />
              </CustomItemLabeled>

              <CustomItemLabeled>
                <DateField source="updatedAt" emptyText="-" />
              </CustomItemLabeled>
            </Row>
          </Column>
          <Column sx={12} sm={12} md={3}>
            <Row>
              <Item sx={12} sm={12} md={12}>
                <CustomImageField
                  sx={{ width: "100%", borderRadius: 20 }}
                  source="logo"
                  size="medium"
                  title="logo"
                />
              </Item>
            </Row>
            <Row>
              <Item sx={12} sm={12} md={12}>
                <CustomMapField source="location" />
              </Item>
            </Row>
          </Column>
        </Container>
      </Tab>
      <Tab label="users">
        <Row>
          <Item xs={12} sm={12} md={12}>
            <CustomNestedList resource="users" source={"institution"}>
              <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="username" emptyText="-" />
                <TextField source="name" emptyText="-" />
                <TextField source="surname" emptyText="-" />
                <TextField source="city" emptyText="-" />
                <TextField source="birthdate" emptyText="-" />
              </Datagrid>
            </CustomNestedList>
          </Item>
        </Row>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

import React from "react";
import { RecordContextProvider, TextField } from "react-admin";
import Item from "../layouts/Item";
import CustomCard from "../cards/CustomCard";
import ReportIcon from "@mui/icons-material/Report";
import Row from "../layouts/Row";
import CustomItemLabeled from "../fields/CustomItemLabeled";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";  
import { Grid } from "@mui/material";
import CustomImageField from "../CustomImageField";


const SimpleUserCard = (props) => {
  
  return (
    <RecordContextProvider value={props.record}>
        <Grid container spacing={2} p={2}>
            <Row>
                <CustomItemLabeled itemProps={{ sx: { marginBottom: 2 }, md: 9 }}>
                    <TextField
                        fontSize={20}
                        fontWeight={"bold"}
                        source="username"
                        fullWidth
                        emptyText="-"
                    />
                </CustomItemLabeled>
            </Row>
            <Row>
                <Item xs={2} sm={2} md={6}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="avatar"
                    size="medium"
                    title="avatar"
                  />
                </Item>
                <Item xs={2} sm={2} md={2}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.0"
                    size="medium"
                    title="photos.0"
                  />
                </Item>
                <Item xs={2} sm={2} md={2}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.1"
                    size="medium"
                    title="photos.1"
                  />
                </Item>
                <Item xs={2} sm={2} md={2}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.2"
                    size="medium"
                    title="photos.2"
                  />
                </Item>
            </Row>
            <Item>
                <CustomCard
                link={"4"}
                source="userA"
                resource="reports"
                label="reports"
                icon={<ReportIcon htmlColor="white" />}
                />
            </Item>
            <Item>
                <CustomCard
                link={"1"}
                source="user"
                resource="user-plans"
                label="plans"
                icon={<LocalActivityIcon htmlColor="white" />}
                />
            </Item>
            <Item>
                <CustomCard
                link={"2"}
                source="users"
                resource="matches"
                label="matches"
                icon={<JoinInnerIcon htmlColor="white" />}
                />
            </Item>
            <Item>
                <CustomCard
                link={"3"}
                source="users"
                resource="meetings"
                label="meetings"
                icon={<JoinInnerIcon htmlColor="white" />}
                />
            </Item>
        </Grid>
    </RecordContextProvider>
  );
};

export default SimpleUserCard;


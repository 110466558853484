import React from "react";
import Dropzone from "react-dropzone";
import { useInput } from "react-admin";
import dataProvider from "../api/dataProvider";
import { useFormContext } from "react-hook-form";
import config from "../config/config";
import Box from "@mui/material/Box";

const CustomImageInput = (props) => {
  //const record = useRecordContext();
  const input = useInput(props);
  const { type } = props;
  const { setValue } = useFormContext();

  const _onDrop = async (e) => {
    let response = await dataProvider.uploadFile(e[0], type);
    setValue(props.source, response, { shouldDirty: true });
  };
  return (
    <Box mb={2}>
      <Dropzone onDrop={_onDrop} accept={props.accept} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              style={{ padding: 10, border: "4px dotted rgba(23,23,23,0.2)" }}
            >
              <input {...getInputProps()} />
              {input.field.value ? (
                <div style={{ marginBottom: 16 }}>
                  <img
                    style={{ width: "100%" }}
                    //style={sx}
                    src={`${config.bucketUrl}/medium/${input.field.value}`}
                    alt={"title"}
                  />
                </div>
              ) : null}
              <p>Drag 'n' drop some files here, or click to select files</p>
              {/*<TextInput source={props.source} />*/}
            </div>
          </section>
        )}
      </Dropzone>
      {/*<TextInput source={props.source} />*/}
    </Box>
  );
};

export default CustomImageInput;

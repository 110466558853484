// in src/CustomImageField.js
import * as React from "react";
import { useRecordContext } from "react-admin";
import config from "../config/config";

const CustomImageField = ({ source, size, title, sx }) => {
  const record = useRecordContext();
  let value = source.split(".").reduce((o, i) => {
    return o[i];
  }, record);
  return value ? (
    <div>
      <img
        style={sx}
        src={`${config.bucketUrl}/${size}/${value}`}
        alt={title}
      />
    </div>
  ) : null;
};

export default CustomImageField;

import {
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  useRecordContext,
} from "react-admin";
import Box from "@mui/material/Box";

const CustomEditToolbar = (props) => {
  const record = useRecordContext();

  return (
    <Toolbar name={props.name}>
      <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
        <Box sx={{ flex: 1 }}>
          <SaveButton />
        </Box>
        {props.showDelete ? (
          <Box>
            <DeleteWithConfirmButton
              confirmContent="You will not be able to recover this record. Are you sure?"
              translateOptions={
                props.name ? { name: record[props.name] } : null
              }
            />
          </Box>
        ) : null}
      </Box>
    </Toolbar>
  );
};

export default CustomEditToolbar;

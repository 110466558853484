// in src/Item.js
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Grid,
} from "@mui/material";

import UserIcon from "@mui/icons-material/Group";

import { Link, useGetList, useRecordContext, useShowContext } from "react-admin";
import { MAIN_COLOR } from "../../utils/colors";

const CustomCard = (props) => {
  // const {
  //   //    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
  //   //    error, // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
  //   //    isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
  //   //    isLoading, // boolean that is true until the record is available for the first time
  //   record, // record fetched via dataProvider.getOne() based on the id from the location
  //   //    refetch, // callback to refetch the record via dataProvider.getOne()
  //   //    resource, // the resource name, deduced from the location. e.g. 'posts'
  // } = useShowContext();
  const record = useRecordContext();

  const { total, isLoading, error } = useGetList(props.resource, {
    filter: { [props.source]: record ? record.id : null, ...props.filter },
    pagination: { page: 1, perPage: 1 },
    sort: { field: "createdAt", order: "DESC" },
  });

  return (
    <Link to={`${props.link || props.resource}`}>
      <Card sx={{ borderRadius: 2, backgroundColor: MAIN_COLOR }}>
        <Box p={2}>
          <Grid container>
            <Grid item xs={9}>
              {props.icon}
              <Typography color={"white"}>{props.label}</Typography>
            </Grid>
            <Grid item>
              <Typography
                color={"white"}
                variant="h4"
                component="h4"
                fontWeight={"bold"}
              >
                {total}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Link>
  );
};

export default CustomCard;

import React from 'react';
import { Button, TextInput, useLogin, useNotify, Form, PasswordInput, Link, useRedirect } from 'react-admin';
import SendIcon from '@mui/icons-material/Send';
// in src/Item.js
import {
    Box,
  } from "@mui/material";
import authProvider from '../../api/authProvider';
import AuthLayout from './authLayout';

const ForgotPassword = () => {

    const notify = useNotify();
    const redirect = useRedirect();
    
    const submit = async (data) => {
        authProvider.forgotPassword(data).then(() => {
            notify("Email sent", { type: 'success' });
            redirect("/recover-password");
        }).catch(() => { notify("Invalid email or dni", { type: 'error' }); });
    }

    return (
        <AuthLayout title="Forgot password?">
            <Form onSubmit={submit}>
                <TextInput source="email" fullWidth  autoComplete="email"/>
                {/* <TextInput source="dni" fullWidth autoComplete='dni' /> */}
                <Button type="submit" label="Send recover password email"><SendIcon /></Button>
            </Form>
        </AuthLayout>
    );
};

export default ForgotPassword;
import { ChipField, useRecordContext } from 'react-admin';
import { Chip } from '@mui/material';

const SimpleArrayField = (props) => {
    const record = useRecordContext();
    return (
        <div>
            {record[props.source].map((item, index) => (
                <Chip key={index} label={item} />
            ))}
        </div>
    )
};

export default SimpleArrayField;